<template>
  <v-dialog :modelValue="dialog" @click:outside="$emit('updateDialog')" width="750">
    <v-card>
      <v-card-title class="title-modal">
        <span class="headline">DETALLE</span>
      </v-card-title>

      <v-card-text>
        <v-form ref="form">
          <v-combobox
            v-model="item.product"
            name="product"
            item-title="label"
            item-value="id"
            :items="products"
            label="Producto*"
            autocomplete="off"
            :rules="[rules.requiredComboBox]"            
            clearable
          ></v-combobox>

          <!-- validar numerico  -->
          <v-text-field autocomplete="off" name="amount" label="Cantidad*" type="text" v-model="item.amount" :rules="[rules.required]" />

          <v-text-field autocomplete="off" name="lote" label="Lote" type="text" v-model="item.lote" />

          <v-text-field autocomplete="off" name="serie" label="Nº Serie" type="text" v-model="item.serie" />

          <v-row>
            <v-col cols="6">
              <v-text-field v-model="item.manufacture_date" name="manufacture_date" label="Fecha de Fabricación" scrollable type="date"></v-text-field>
            </v-col>
            <v-col cols="6">
              <v-text-field v-model="item.expiration_date" name="expiration_date" label="Fecha de Expiración" scrollable type="date"></v-text-field>
            </v-col>
          </v-row>

          <v-text-field autocomplete="off" name="external_line_number" label="Numero de linea externo" type="text" v-model="item.external_line_number" />
        </v-form>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="red" text @click="$emit('updateDialog')"> Cancelar </v-btn>
        <v-btn color="green" text @click="addNewPresentation()"> {{ itemEdit != null ? "Editar" : "Agregar" }} </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import Constant from "@/constants/sections";
import { generalService } from "@/libs/ws/generalService";
import { productoService } from "@/libs/ws/productoService";
import ValidMixin from "@/mixins/ValidMixin.vue";

export default {
  created() {},
  mixins: [ValidMixin],
  components: {},
  props: {
    dialog: Boolean,
    shipment_order: Object,
    updateDialog: Function,
    addPresentation: Function,
    itemEdit: Object,
  },
  data: () => ({
    item: {      
      product: null,
      amount: null,
      manufacture_date: null,
      expiration_date: null,
      lote: null,
      serie: null,
      external_line_number: "",
    },
    defaultItem: null,
    presentation_products: [],
    products: [],
  }),
  watch: {
    dialog() {
      if (this.itemEdit == null) {
        this.item = JSON.parse(JSON.stringify(this.defaultItem));
      } else {
        this.item = JSON.parse(JSON.stringify(this.itemEdit));
      }
    },
  },
  mounted() {
    this.loadProducts();
    this.defaultItem = JSON.parse(JSON.stringify(this.item));
  },
  methods: {
    loadProducts() {
      let client = this.shipment_order.client != null ? this.shipment_order.client.id : null;

      productoService.filter({ client: client }).then((response) => {
        this.products = response.result.list;
      });
    },    
    async addNewPresentation() {
      const { valid } = await this.$refs.form.validate();
      if (valid) {
        console.log(this.item);
        this.$emit("addPresentation", this.item);
      }
    },
  },
};
</script>
