import { wsService } from "./wsService";

const section = "picking-detail";

function filter(param = { enable: true }) {
  return wsService.make_post_request(section + "/list", param);
}

function create(param = {}) {
  return wsService.make_post_request(section + "/create", param);
}

function update(param = {}) {
  return wsService.make_put_request(section + "/update", param);
}

function remove(param = {}) {
  return wsService.make_delete_request(section + "/delete", param);
}

function get(param = { enable: true }) {
  return wsService.make_post_request(section + "/get", param);
}

function list_status() {
  return wsService.make_get_request(section + "/list_status");
}

export const pickingDetailService = {
  section,
  filter,
  remove,
  create,
  update,
  get,
  list_status,
};
