<template>
  <div>
    <Title :pages="[{ icon: 'arrow-right', page: page_title.toUpperCase() }]"></Title>
    <v-container fluid>
      <!-- v-if="create_access" -->
      <Modal
        v-if="create_access"
        :button_name="'Crear ' + modal_title"
        :modal_title="modal_title"
        :page_title="page_title"
        :formBtn="formBtn"
        :dialog="dialog"
        :service="service"
        @updateDialog="updateDialog"
        @loadList="loadList(service)"
        :editItem="mainItem"
      />

      <!-- 
        desde
        hasta
        tipo de documento
        estado
        cliente 
        referencia externa 
        -->

      <v-card class="mb-4">
        <div class="row mb-2 ps-3 pe-3 mt-0 mb-2">
          <div class="col-md-12">
            <div class="col-md-12" style="background: white">
              <p class="text-h6 mt-2 mb-0"><font-awesome-icon icon="filter" class="pr-2" />Filtros</p>
              <v-divider class="mt-0"></v-divider>
              <div class="row pt-2">
                <div class="col-md-3">
                  <v-text-field v-model="filters.estimated_arrival_date_from" density="comfortable" type="date" name="estimated_arrival_date_from" label="Desde" :disabled="disabled"></v-text-field>
                </div>
                <div class="col-md-3">
                  <v-text-field v-model="filters.estimated_arrival_date_to" density="comfortable" type="date" name="estimated_arrival_date_to" label="hasta" :disabled="disabled"></v-text-field>
                </div>
                <div class="col-md-3">
                  <v-combobox
                    v-model="filters.document_type"
                    name="document_type"
                    density="comfortable"
                    item-title="name"
                    item-value="id"
                    :items="documents_types"
                    label="Tipo de Documento"
                    autocomplete="off"
                    :disabled="disabled"
                    clearable
                  ></v-combobox>
                </div>
                <div class="col-md-3">
                  <v-text-field v-model="filters.external_reference" density="comfortable" label="Referencia Externa" :disabled="disabled"></v-text-field>
                </div>
                <div class="col-md-3">
                  <v-text-field v-model="filters.id" density="comfortable" label="Numero" :disabled="disabled"></v-text-field>
                </div>
                <div class="col-md-3">
                  <v-combobox
                    v-model="filters.status"
                    density="comfortable"
                    name="status"
                    item-title="name"
                    item-value="id"
                    :items="status"
                    label="Estado"
                    autocomplete="off"
                    :disabled="disabled"
                    clearable
                  ></v-combobox>
                </div>
                <div class="col-md-3" v-if="user_type != userTypeConstant.CLIENT">
                  <v-combobox
                    v-model="filters.client"
                    density="comfortable"
                    name="cliente"
                    item-title="name"
                    item-value="id"
                    :items="clients"
                    label="Cliente"
                    autocomplete="off"
                    :disabled="disabled"
                    clearable
                  ></v-combobox>
                </div>
                <div class="col-md-3">
                  <v-combobox
                    v-model="filters.almacen"
                    density="comfortable"
                    name="almacen"
                    item-title="name"
                    item-value="id"
                    :items="almacenes"
                    label="Almacen"
                    autocomplete="off"
                    :disabled="disabled"
                    clearable
                  ></v-combobox>
                </div>

                <div :class="user_type == userTypeConstant.CLIENT ? 'col-md-3 pt-2' : 'col-md-12 pt-0 pb-3'" style="display: flex; justify-content: end">
                  <v-btn color="info" class="" @click="filter" prepend-icon="mdi-magnify">Buscar</v-btn>
                </div>
              </div>
            </div>
          </div>
        </div>
      </v-card>

      <v-card>
        <v-card-title>
          <v-row align="center" class="mt-1">
            <v-col>
              <span>{{ page_title }}</span>
            </v-col>
            <v-spacer></v-spacer>
            <v-col>
              <v-text-field v-model="search" append-inner-icon="mdi-magnify" single-line hide-details label="Buscar" density="compact"></v-text-field>
            </v-col>
          </v-row>
        </v-card-title>

        <!-- v-if="list_access" -->

        <v-data-table v-if="list_access" :headers="headers" :items="list" :search="search" :loading="loading" loading-text="Cargando...">
          <template v-slot:item="{ item }">
            <tr>
              <td>{{ item.id }}</td>
              <td>{{ item.estimated_arrival_date }}</td>
              <td>{{ item.almacen.name }}</td>
              <td v-if="user_type != userTypeConstant.CLIENT">{{ item.client.user.username }}</td>
              <td>{{ item.document_type.name }}</td>
              <td>{{ item.external_reference }}</td>
              <td>{{ item.status.name }}</td>

              <td class="text-center">
                <v-tooltip bottom v-if="user_type != userTypeConstant.CLIENT">
                  <template v-slot:activator="{ props }">
                    <v-icon v-if="update_access" v-bind="props" small color="green" class="mr-2" v-on:click="goToRecepcion(item)" size="small"> mdi-arrow-right-box</v-icon>
                  </template>
                  <span v-if="!service.STATE_CERRADO">Recibir</span>
                  <span v-else>Ver detalle de recepción</span>
                </v-tooltip>

                <v-tooltip bottom v-if="item.status.id != service.STATE_PENDIENTE">
                  <template v-slot:activator="{ props }">
                    <v-icon v-bind="props" small color="violet" class="mr-2" v-on:click="openSummary(item)" size="small"> mdi-eye </v-icon>
                  </template>
                  <span>Ver resumen de recepción</span>
                </v-tooltip>

                <v-tooltip bottom>
                  <template v-slot:activator="{ props }">
                    <v-icon v-if="update_access" v-bind="props" small color="blue" class="mr-2" v-on:click="editItem(item)" size="small"> mdi-pencil</v-icon>
                  </template>
                  <span>Editar</span>
                </v-tooltip>

                <v-tooltip bottom>
                  <template v-slot:activator="{ props }">
                    <v-icon v-bind="props" small color="blue" class="mr-2" v-on:click="viewItem(item)" size="small"> mdi-eye </v-icon>
                  </template>
                  <span>Ver</span>
                </v-tooltip>

                <v-tooltip bottom>
                  <template v-slot:activator="{ props }">
                    <v-icon v-if="delete_access" v-bind="props" small color="red" class="mr-2" v-on:click="deleteItem(item)" size="small">mdi-delete </v-icon>
                  </template>
                  <span>Eliminar</span>
                </v-tooltip>
              </td>
            </tr>
          </template>
        </v-data-table>
      </v-card>

      <ModalSummary :asn="asn" :dialog="dialog_summary" @updateDialog="dialog_summary = false" />

      <DialogDelete :dialog_delete="dialog_delete" @updateDialogDelete="updateDialogDelete" @deleteItemConfirm="deleteItemConfirm" :loading="loadingSubmit" />
    </v-container>
  </div>
</template>

<script>
import { asnService } from "@/libs/ws/asnService";
import { clientService } from "@/libs/ws/clientService";
import { documentsTypeService } from "@/libs/ws/documentsTypeService";
import { almacenService } from "@/libs/ws/almacenService";
import UserTypeConstant from "@/constants/user_type";
import DialogDelete from "@/components/DialogDelete.vue";
import GenericMixin from "@/mixins/GenericMixin.vue";
import RolMixin from "@/mixins/RolMixin.vue";
import Modal from "./Modal.vue";
import Constant from "@/constants/sections";
import ModalSummary from "./ModalSummary.vue";
export default {
  components: { Modal, ModalSummary, DialogDelete },
  mixins: [GenericMixin, RolMixin],
  data() {
    return {
      service: asnService,
      userTypeConstant: UserTypeConstant,
      user_type: null,
      page_title: "ASN",
      modal_title: "ASN",
      asn: null,
      dialog_summary: false,
      documents_types: [],
      clients: [],
      almacenes: [],
      status: [],
      headers: [
        {
          title: "Numero",
          align: "left",
          sortable: true,
          key: "id",
        },
        {
          title: "Fecha estimada",
          align: "left",
          sortable: true,
          key: "estimated_arrival_date.date",
        },
        {
          title: "Almacen",
          align: "left",
          sortable: true,
          key: "almacen.name",
        },
        {
          title: "Cliente",
          align: "left",
          sortable: true,
          key: "client.user.username",
          visible_for_client: false,
        },
        {
          title: "Tipo de Documento",
          align: "left",
          sortable: true,
          key: "document_type.name",
        },
        {
          title: "Referencia Externa",
          align: "left",
          sortable: true,
          key: "external_reference",
        },
        {
          title: "Estado",
          align: "left",
          sortable: true,
          key: "status",
        },
        { title: "Accion", align: "center", sortable: false, key: "" },
      ],
      filters: {
        estimated_arrival_date_from: new Date().toISOString().substr(0, 10),
        estimated_arrival_date_to: new Date().toISOString().substr(0, 10),
        estimated_arrival_date: "",
        document_type: null,
        status: null,
        client: null,
        almacen: null,
        id: "",
        external_reference: "",
      },
    };
  },
  mounted() {
    this.user_type = localStorage.getItem("user_type");
    //recorro los headers y saco el cliente si el usuario es el cliente
    this.headers = this.headers.filter((header) => {
      if (header.visible_for_client || header.visible_for_client == undefined) {
        return true;
      }
      return this.user_type != UserTypeConstant.CLIENT;
    });

    this.loadList(this.service);
    this.loadAlmacenes();
    this.loadClientes();
    this.loadDocumentTypes();
    this.loadStatus();
  },
  methods: {
    openSummary(item) {
      this.asn = item;
      this.dialog_summary = true;
    },
    goToRecepcion(item) {
      this.$router.push("/" + Constant.SECTION_ASN + "/recepcion/" + item.id);
    },
    getFilterParams() {
      return this.filters;
    },
    filter() {
      this.loadList(this.service);
    },
    loadAlmacenes() {
      almacenService
        .filter({})
        .then((response) => {
          this.almacenes = response.result.list;
        })
        .catch((e) => {
          console.log("service.load error");
          console.log(e);
        });
    },
    loadClientes() {
      clientService
        .filter({})
        .then((response) => {
          this.clients = response.result.list;
        })
        .catch((e) => {
          console.log("service.load error");
          console.log(e);
        });
    },
    loadDocumentTypes() {
      documentsTypeService
        .filter()
        .then((response) => {
          this.documents_types = response.result.list;
        })
        .catch((e) => {
          console.log("service.load error");
          console.log(e);
        });
    },
    loadStatus() {
      asnService
        .list_status()
        .then((response) => {
          this.status = response.result.list;
        })
        .catch((e) => {
          console.log("service.load error");
          console.log(e);
        });
    },
  },
};
</script>
