import { wsService } from "./wsService";

const section = "zonas";

function filter(param = { enable: true }) {
  return wsService.make_post_request(section + "/list", param);
}

function create(param = {}) {
  return wsService.make_post_request(section + "/create", param);
}

function update(param = {}) {
  return wsService.make_put_request(section + "/update", param);
}

function remove(param = {}) {
  return wsService.make_delete_request(section + "/delete", param);
}

function get_ubicaciones_disponibles(param = {}) {
  return wsService.make_post_request(section + "/get-ubicaciones-disponibles", param);
}

function get_ubicaciones(param = {}) {
  return wsService.make_post_request(section + "/get-ubicaciones", param);
}

function getUbicacionCode(zona,ubicacion){  
  let code = 
    String(zona.code).padStart(3, '0') + "-" + 
    String(ubicacion.rack).padStart(3, '0') + "-" + 
    String(ubicacion.col).padStart(3, '0') + "-" + 
    String(ubicacion.level).padStart(3, '0');
  return code;
}

export const zonaService = {
  section,
  filter,
  remove,
  create,
  update,
  get_ubicaciones,
  get_ubicaciones_disponibles,  
  getUbicacionCode,
};
