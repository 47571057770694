<template>
  <v-layout>
    <v-dialog :modelValue="dialog" @click:outside="$emit('updateDialog', false)" max-width="500px" transition="dialog-bottom-transition">
      <template v-slot:activator="{ props }">
        <v-btn flat class="mb-3 btn-second" v-bind="props" @click="$emit('updateDialog', true)">{{ button_name }}</v-btn>
      </template>

      <v-card>
        <v-form @submit.prevent ref="form" v-model="valid" v-on:submit.prevent="createItem">
          <v-card-title>
            <span class="headline">{{ modal_title }}</span>
          </v-card-title>

          <v-card-text>
            <!-- START FORM DIALOG -->
            <div class="row">
              <div class="col-md-12">
                <p class="fs-16">Ingrese la cantidad de etiquetas a crear</p>
                <v-text-field label="Cantidad de etiquetas*" type="number" v-model="item.amount" :disabled="disabled" :rules="[rules.required, rules.integer]"></v-text-field>

                <p class="fs-16">Seleccione el tipo de etiqueta</p>
                <v-radio-group v-model="item.flow_type">
                  <v-radio style="margin-top: 5px" label="Recepción" value="I"></v-radio>
                  <v-radio style="margin-top: 5px" label="Expedición" value="O"></v-radio>
                </v-radio-group>
              </div>
            </div>
          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="error" text @click="$emit('updateDialog', false)">Cerrar</v-btn>
            <v-btn color="success" text type="submit" :loading="loading" v-if="!disabled">{{ formBtn }}</v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
  </v-layout>
</template>

<script>
import ValidMixin from "@/mixins/ValidMixin.vue";
export default {
  mixins: [ValidMixin],
  props: {
    service: Object,
    button_name: String,
    modal_title: String,
    page_title: String,
    formBtn: String,
    dialog: Boolean,
    updateDialog: Function,
    loadList: Function,
    editItem: Object,
  },
  data() {
    return {
      show: false,
      disabled: false,
      loading: false,
      name: "",
      item: {
        amount: 1,
        flow_type: "I",
        format_page: "zpl",
      },
      defaultItem: null,
    };
  },
  watch: {
    dialog() {
      if (this.dialog) {
        this.disabled = this.formBtn == "";

        if (this.$refs.form) {
          this.$refs.form.resetValidation();
        }

        if (this.editItem != null && this.editItem.id > 0) {
          this.item = JSON.parse(JSON.stringify(this.editItem));
        } else {
          if (this.defaultItem != null) this.item = JSON.parse(JSON.stringify(this.defaultItem));
        }
      }
    },
  },
  mounted() {
    this.defaultItem = JSON.parse(JSON.stringify(this.item));
  },
  methods: {
    customValidate() {
      if (this.item.amount < 1) {
        this.toast.error("La cantidad de etiquetas debe ser mayor a 0");
        return false;
      }
      if (this.item.amount > 100) {
        this.toast.error("La cantidad de etiquetas no puede ser mayor a 100");
        return false;
      }
      return true;
    },
    createItem() {
      if (this.loading) return;

      if (!this.customValidate()) {
        return;
      }

      if (this.valid) {
        this.loading = true;

        var data = this.item;

        this.service
          .create(data)
          .then((response) => {
            if (response.status) {
              this.toast.success("El " + this.modal_title + " se creo correctamente");
              this.$emit("updateDialog", false);
              this.$emit("loadList");
            } else {
              if (Object.prototype.hasOwnProperty.call(response, "message")) {
                this.toast.error(response.message);
              } else {
                this.toast.error(response.msg);
              }
            }
          })
          .catch((e) => {
            this.toast.error(e);
          })
          .finally(() => {
            this.loading = false;
          });
      }
    },
  },
};
</script>
