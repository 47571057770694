<template>
  <div>
    <v-form ref="form_step1">
      <v-row>
        <v-col md="6">
          <v-combobox
            v-model="shipment_order_AUX.sub_client"
            name="sub_client"
            item-title="name"
            item-value="id"
            :items="subclients"
            label="Sub Cliente"
            autocomplete="off"
            :loading="loading"
            :disabled="disabled"
            @update:modelValue="loadDestino"
            clearable
          ></v-combobox>
        </v-col>
        <v-col md="6">
          <v-text-field label="Destinatario" v-model="shipment_order_AUX.receiver" :disabled="disabled"></v-text-field>
        </v-col>
        <v-col md="12">
          <v-text-field label="Dirección*" v-model="shipment_order_AUX.address" :disabled="disabled" :rules="[rules.required]"></v-text-field>
        </v-col>
        <v-col md="4">
          <v-text-field label="Localidad*" v-model="shipment_order_AUX.location" :disabled="disabled" :rules="[rules.required]"></v-text-field>
        </v-col>
        <v-col md="4">
          <v-text-field label="Código Postal*" v-model="shipment_order_AUX.postal_code" :disabled="disabled" :rules="[rules.required]"></v-text-field>
        </v-col>
        <v-col md="4">
          <v-combobox
            v-model="shipment_order_AUX.provincia"
            name="provincia"
            item-title="name"
            item-value="id"
            :items="provincias"
            label="Provincia*"
            autocomplete="off"
            :disabled="disabled"
            :rules="[rules.requiredComboBox]"
            clearable
          ></v-combobox>
        </v-col>
      </v-row>
    </v-form>

    <v-btn v-if="step > 0" @click="$emit('prev')" class="mt-5" color="blue-grey">Atras</v-btn>
    <v-btn class="float-right mt-5 btn-second" v-if="step < steps.length - 1" @click="_next">Siguiente</v-btn>
  </div>
</template>

<script>
import Constant from "@/constants/sections";
import { subClientService } from "@/libs/ws/subClientService";
import { generalService } from "@/libs/ws/generalService";
import ValidMixin from "@/mixins/ValidMixin.vue";
export default {
  components: {},
  mixins: [ValidMixin],
  props: {
    open: Boolean,
    step: Number,
    steps: Array,
    shipment_order: Object,
    validator: Object,
    isViewModal: {
      type: Boolean,
      default: false,
    },
    disabled: Boolean,
    prev: Function,
    next: Function,
    updateShipmentOrderItem: Function,
  },
  data: () => ({
    shipment_order_AUX: {
      //Objeto para mostrar datos dentro del componente y evitar el mutating al padre
      sub_client: null,
      receiver: null,
      address: null,
      location: null,
      postal_code: null,
      provincia: null,
    },
    itemEdit: null,
    itemEditIndex: null,
    subclients: [],
    provincias: [],
    loading: false,
  }),
  watch: {},
  mounted() {
    if (this.shipment_order.id > 0) {
      this.shipment_order_AUX = { ...this.shipment_order };
    }
    this.loadProvincias();
    this.loadSubclients();
  },
  methods: {
    loadDestino() {
      this.shipment_order_AUX.receiver = this.shipment_order_AUX.sub_client.name;
      this.shipment_order_AUX.address = this.shipment_order_AUX.sub_client.address;
      this.shipment_order_AUX.location = this.shipment_order_AUX.sub_client.location;
      this.shipment_order_AUX.postal_code = this.shipment_order_AUX.sub_client.postal_code;
      this.shipment_order_AUX.provincia = this.shipment_order_AUX.sub_client.provincia;
    },
    loadProvincias() {
      generalService
        .filter({}, Constant.SECTION_PROVINCIAS)
        .then((response) => {
          this.provincias = response.result.list;
        })
        .catch((e) => {
          console.log("service.load error");
          console.log(e);
        });
    },
    loadSubclients() {
      if (this.disabled) return;
      this.loading = true;

      let client = this.shipment_order.client != null ? this.shipment_order.client.id : null;

      subClientService
        .filter({ client: client })
        .then((response) => {
          this.subclients = response.result.list;
        })
        .catch((e) => {
          console.log("service.load error");
          console.log(e);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    async _next() {
      const { valid } = await this.$refs.form_step1.validate();
      if (!valid) {
        return;
      }
      this.$emit("updateShipmentOrderItem", { ...this.shipment_order_AUX });
      this.$emit("next");
    },
  },
};
</script>
