import { wsService } from "./wsService";

const section = "products";

const PRESENTATION_TYPE_UNIDAD = 1;
const PRESENTATION_TYPE_CAJA = 2;
const PRESENTATION_TYPE_PALLET = 3;

function filter(param = { enable: true }) {
  return wsService.make_post_request(section + "/list", param);
}

function create(param = {}) {
  return wsService.make_post_request(section + "/create", param);
}

function update(param = {}) {
  return wsService.make_put_request(section + "/update", param);
}

function remove(param = {}) {
  return wsService.make_delete_request(section + "/delete", param);
}

function list_presentations() {
  return wsService.make_post_request(section + "/presentations");
}

function filter_presentations() {
  return wsService.make_post_request(section + "/filter_presentations");
}

function getStates() {
  return wsService.make_get_request(section + "/product_status");
}

function tiposRotacoin() {
  return wsService.make_get_request(section + "/tipos_rotacion");
}

function frecuenciasRotacoin() {
  return wsService.make_get_request(section + "/frecuencias_rotacion");
}

function compositePresentationAmountFor(product_presentation_id, product_id, presentation_type_id) {
  const params = {
    product_presentation: product_presentation_id,
    product: product_id,
    presentation_type: presentation_type_id,
  }
  return wsService.make_post_request(section + "/composite_presentation_amount_for",params);
}


export const productoService = {
  section,
  filter,
  remove,
  create,
  update,
  list_presentations,
  getStates,
  tiposRotacoin,
  frecuenciasRotacoin,  
  compositePresentationAmountFor,
  PRESENTATION_TYPE_UNIDAD,
  PRESENTATION_TYPE_CAJA,
  PRESENTATION_TYPE_PALLET,
};
