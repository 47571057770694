<template>
  <div>
    <Title
      :pages="[
        { icon: 'arrow-right', page: 'ASN' },
        { icon: '', page: page_title.toUpperCase() },
      ]"
    ></Title>
    <v-container fluid>
      <!-- HEADER ASN -->
      <v-row v-if="asn != null">
        <v-col class="px-5">
          <v-row align="center" class="mt-1 box-border">
            <v-col>
              <v-col class="v-col text-center text-button font-weight-bold pb-0"> ASN </v-col>
              <v-col class="text-center pt-0"> #{{ asn.id }} </v-col>
            </v-col>
            <v-divider vertical></v-divider>
            <v-col>
              <v-col class="v-col text-center text-button font-weight-bold pb-0"> Cliente </v-col>
              <v-col class="text-center pt-0"> {{ asn.client.name }}</v-col>
            </v-col>
            <v-divider vertical></v-divider>
            <v-col>
              <v-col class="v-col text-center text-button font-weight-bold pb-0"> Documento </v-col>
              <v-col class="text-center pt-0">{{ asn.document_type.name }} </v-col>
            </v-col>
            <v-divider vertical></v-divider>
            <v-col>
              <v-col class="v-col text-center text-button font-weight-bold pb-0"> Fecha Estimada </v-col>
              <v-col class="text-center pt-0"> {{ asn.estimated_arrival_date }} </v-col>
            </v-col>
            <v-divider vertical></v-divider>
            <v-col>
              <v-col class="v-col text-center text-button font-weight-bold pb-0"> Estado </v-col>
              <v-col class="text-center pt-0"> {{ asn.status.name }} </v-col>
            </v-col>
          </v-row>
        </v-col>
      </v-row>

      <v-row v-if="asn != null && asn.asn_details_received.length == 0">
        <v-col class="px-5">
          <v-row>
            <v-col class="justify-content-center d-flex">
              <img src="@/assets/images/recibir.svg" alt="asn" class="img-fluid" width="400px" />
            </v-col>
          </v-row>

          <v-row>
            <v-col class="justify-content-center d-flex">
              <v-btn flat class="mb-3" color="green" @click="recibir()">Comenzar recepción</v-btn>
            </v-col>
          </v-row>
        </v-col>
      </v-row>

      <v-row v-if="asn != null && asn.asn_details_received.length > 0">
        <v-col class="px-5">
          <v-row v-if="asn != null && asn.status.id != service.STATE_CERRADO">
            <v-col class="px-0 mb-2">
              <v-btn flat color="green" @click="recibir()">Nueva recepción</v-btn>
            </v-col>
            <v-col class="px-0 mb-2 justify-content-end d-flex">
              <v-btn flat color="blue-grey-lighten-3" border size="small" @click="dialog_close = true">Finalizar recepción</v-btn>
            </v-col>
          </v-row>
          <v-row class="mt-2 header-grey">
            <v-col cols="3" class="p-0 justify-content-center d-flex text-button font-weight-bold font-12"> Producto </v-col>
            <v-divider vertical></v-divider>
            <v-col class="p-0 justify-content-center d-flex text-button font-weight-bold font-12"> Recepcion </v-col>
            <v-divider vertical></v-divider>
            <v-col cols="1" class="p-0 justify-content-center d-flex text-button font-weight-bold font-12"> Cant. </v-col>
            <v-divider vertical></v-divider>
            <v-col class="p-0 justify-content-center d-flex text-button font-weight-bold font-12"> Estado </v-col>
            <v-divider vertical></v-divider>
            <v-col class="p-0 justify-content-center d-flex text-button font-weight-bold font-12"> LPN </v-col>
            <v-divider vertical></v-divider>
            <v-col class="p-0 justify-content-center d-flex text-button font-weight-bold font-12"> Info Extra </v-col>
            <v-divider vertical></v-divider>
            <v-col class="p-0 justify-content-center d-flex text-button font-weight-bold font-12"> Acciones </v-col>
          </v-row>

          <v-row v-for="(item, index) in asn.asn_details_received" :key="index" class="mt-4 box-border">
            <v-col cols="3" class="justify-content-center d-flex font-14"> {{ item.presentation_atributes.product_presentation.label }} </v-col>
            <v-col class="justify-content-center d-flex font-14"> {{ item.accepted ? "Aceptada" : "Rechazada" }} </v-col>
            <v-col cols="1" class="justify-content-center d-flex font-14"> {{ item.amount }} </v-col>
            <v-col class="justify-content-center d-flex font-14"> {{ item.accepted ? item.presentation_atributes.product_status.name : "-" }} </v-col>
            <v-col class="justify-content-center d-flex font-14"> {{ item.accepted ? item.lpn.code : "-" }} </v-col>
            <v-col class="justify-content-center d-flex font-14"> {{ getInfoExtra(item) }} </v-col>
            <v-col class="justify-content-center d-flex font-14">
              <v-tooltip bottom>
                <template v-slot:activator="{ props }">
                  <v-icon v-if="delete_access && asn != null && asn.status.id != service.STATE_CERRADO" v-bind="props" small color="red" class="mr-2" v-on:click="dialogDelete(item)" size="small"
                    >mdi-delete
                  </v-icon>
                </template>
                <span>Eliminar</span>
              </v-tooltip>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-container>

    <ModalRecepcion :asn="asn" :dialog="dialog_recepcion" @updateDialog="dialog_recepcion = false" @loadList="getASN()" />
    <DialogYesNo :open="dialog_close" :loading="loading_close" title="Confirmar" message="¿Estas seguro que quieres cerrar la ASN?" @cancel="dialog_close = false" @accept="close" />

    <DialogYesNo :open="dialog_delete" :loading="loading_delete" title="Confirmar" message="¿Estas seguro que quieres eliminar la recepcion?" @cancel="dialog_delete = false" @accept="deleteRecepcion" />
  </div>
</template>

<script>
import { asnService } from "@/libs/ws/asnService";
import GenericMixin from "@/mixins/GenericMixin.vue";
import RolMixin from "@/mixins/RolMixin.vue";
import ModalRecepcion from "./ModalRecepcion.vue";
export default {
  components: { ModalRecepcion },
  mixins: [GenericMixin, RolMixin],
  data() {
    return {
      service: asnService,
      page_title: "Recepcion",
      dialog_recepcion: false,
      dialog_close: false,
      asn: null,

      dialog_delete: false,
      loading_delete: false,
      item_selected: null,
    };
  },
  mounted() {
    this.getASN();
  },
  methods: {
    close() {
      this.loading_close = true;
      asnService
        .close({ id: this.$route.params.id })
        .then((response) => {
          if (response.status) {
            this.toast.success(response.msg);
            this.$router.back();
          } else {
            this.toast.error(response.msg);
          }
        })
        .catch((error) => {
          this.toast.error(error);
        })
        .finally(() => {
          this.loading_close = false;
          this.dialog_close = false;
        });
    },
    getInfoExtra(item) {
      if (item.acepted === false) {
        return "Motivo: " + item.reason;
      } else {
        var text = "";
        if (item.presentation_atributes.expiration_date != null) text += "Venc.: " + item.presentation_atributes.expiration_date;
        if (item.presentation_atributes.manufacture_date != null) text += "Fabricacion: " + item.presentation_atributes.manufacture_date;
        if (item.presentation_atributes.lote != null) text += "Lote: " + item.presentation_atributes.lote;
      }
    },
    getASN() {
      asnService.get({ id: this.$route.params.id }).then((response) => {
        this.asn = response.result;
      });
    },
    recibir() {
      this.dialog_recepcion = true;
    },
    dialogDelete(item){
      this.item_selected = item;
      this.dialog_delete = true;    
    },
    deleteRecepcion(){
      this.loading_delete = true;
      asnService.deleteDetailReceived({ id: this.item_selected.id }).then((response) => {
        if (response.status) {
          this.toast.success(response.msg);
          this.getASN();
        } else {
          this.toast.error(response.msg);
        }
      }).catch((error) => {
        this.toast.error(error);
      }).finally(() => {
        this.loading_delete = false;
        this.dialog_delete = false;
      });
    },
  },
};
</script>
