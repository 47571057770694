<template>
  <v-app class="scrollbar-hidden" v-if="!viewRequiresAuth()">
    <router-view></router-view>
  </v-app>

  <v-app class="scrollbar-hidden" v-else>
    <v-navigation-drawer v-model="$store.state.sidebar.drawer" floating :rail="!$store.state.sidebar.open"
      :expand-on-hover="!$store.state.sidebar.open ? true : false" :width="240" dark app class="rounded-0">
      <Sidebar></Sidebar>
    </v-navigation-drawer>

    <v-app-bar class="elevation-0" height="48" dense app
      :style="'left: ' + ($vuetify.display.mdAndDown ? '0px' : $store.state.sidebar.open ? '240px' : '56px') + ' !important'">
      <Header></Header>
    </v-app-bar>
    <v-main>
      <router-view></router-view>
    </v-main>
  </v-app>
</template>

<script>
import Header from "@/components/Header.vue";
import Sidebar from "@/components/Sidebar.vue";
export default {
  name: "App",
  components: { Header, Sidebar },
  data: () => ({}),
  created() {
    document.title = "Akeron - Warehouse Managment System";
  },
  mounted() {
    window.document.title = "Akeron - Warehouse Managment System";
  },
  methods: {
    viewRequiresAuth() {
      return this.$route.matched.some((record) => record.meta.requiresAuth);
    },
  },
};
</script>
