<template>
  <v-layout>
    <v-dialog :modelValue="dialog" @click:outside="$emit('updateDialog', false)" fullscreen :scrim="false" transition="dialog-bottom-transition">
      <template v-slot:activator="{ props }">
        <v-btn flat class="mb-3 btn-second" v-bind="props" @click="$emit('updateDialog', true)">{{ button_name }}</v-btn>
      </template>

      <v-card>
        <v-form @submit.prevent ref="form" v-model="valid" v-on:submit.prevent="createItem">
          <v-card-title>
            <span class="headline">{{ modal_title }}</span>
            <font-awesome-icon class="float-right mt-1 mr-3 cursor" title="Cerrar" size="xl" icon="xmark" color="red" @click="$emit('updateDialog', false)"></font-awesome-icon>
          </v-card-title>

          <v-card-text>
            <v-container fluid class="mt-0 mb-0">
              <v-row align="center" justify="center" class="mb-4">
                <v-col md="6" class="elevation-1 pt-4 pb-4" style="background: #f5f5f5">
                  <a-steps :current="step">
                    <a-step v-for="item in steps" :key="item.title" :title="item.title" />
                  </a-steps>
                </v-col>
              </v-row>

              <div class="steps-content">
                <!--step 0 -->
                <v-row align="start" justify="center" v-if="step == 0" transition="fade-transition" style="height: 67dvh">
                  <v-col md="6" class="mt-4 pr-0 pl-0">
                    <v-form ref="form_step1">
                      <div class="px-2 py-1 mb-3 bg-subtitle-section">
                        <p class="text-subtitle-1 mb-0"><b>Rol</b></p>
                      </div>
                      <p>
                        Los roles le permiten acceder o no a ciertas funcionalidades dentro del sistema. Una vez creado el rol este podrá asignarse a uno o varios empleados. Todos los empleados con el
                        mismo rol tendran los mismos permisos.
                      </p>
                      <v-text-field density="compact" label="Nombre*" v-model="item.name" :disabled="disabled" :rules="[rules.required]"></v-text-field>
                      <v-text-field
                        hint="Complete una descripcion que permita identificar las tareas del Rol"
                        label="Descripcion*"
                        v-model="item.description"
                        :disabled="disabled"
                        :rules="[rules.required]"
                      ></v-text-field>
                      <v-checkbox density="compact" label="Habilitado" :disabled="disabled" v-model="item.enable"></v-checkbox>
                      <v-divider class="mt-0"></v-divider>
                      <v-row>
                        <v-col md="6">
                          <v-checkbox
                            :persistent-hint="true"
                            hint="Permite al rol acceder al aplicativo web"
                            density="compact"
                            label="Acceso web"
                            :disabled="disabled"
                            v-model="item.web_access"
                          ></v-checkbox>
                        </v-col>
                        <v-col md="6">
                          <v-checkbox
                            :persistent-hint="true"
                            hint="Permite al rol acceder al aplicativo mobile"
                            density="compact"
                            label="Acceso mobile"
                            :disabled="disabled"
                            v-model="item.app_access"
                          ></v-checkbox>
                        </v-col>
                      </v-row>
                    </v-form>
                    <v-btn class="float-right mt-5 btn-second" v-if="step < steps.length - 1" @click="next">Siguiente</v-btn>
                  </v-col>
                </v-row>
                <!-- FIN Step 1 -->
                <!-- Step 2 -->
                <div v-if="step == 1" transition="fade-transition">
                  <v-row align="center" justify="center">
                    <v-col md="6" class="mt-4 pr-0 pl-0">
                      <div class="px-2 py-1 mb-3 bg-subtitle-section">
                        <p class="text-subtitle-1 mb-0"><b>Modulo</b></p>
                      </div>
                      <v-alert
                        type="info"
                        title="Modulos"
                        text="A continuación podrá ver el listado de módulos que componen el sistema, permitiendo darle distintos accesos o no al rol dentro de cada módulo."
                      ></v-alert>
                    </v-col>
                  </v-row>
                  <v-row align="center" justify="center">
                    <v-col md="12">
                      <v-data-table
                        :headers="headers_modules"
                        :disabled="disabled"
                        density="compact"
                        :items="item.modules.length != 0 ? item.modules : modules"
                        :loading="loading_table"
                        loading-text="Cargando... Por favor espere"
                      >
                        <template v-slot:headers="{ headers }">
                          <th v-for="(item, index) in headers[0]" :key="index" class="py-3 px-1 text-white bg-grey">
                            <p style="white-space: nowrap">
                              {{ item.title }}
                              <v-tooltip content-class="bg-dark" top v-if="item.tooltip != null">
                                <template v-slot:activator="{ props }">
                                  <font-awesome-icon v-bind="props" icon="info-circle" class="cursor" />
                                </template>
                                <span v-html="item.tooltip"></span>
                              </v-tooltip>
                            </p>
                          </th>
                        </template>

                        <template v-slot:item="{ item }">
                          <tr>
                            <!-- START ROWS -->
                            <!-- Nombre -->
                            <td style="max-width: 200px">{{ item.name }}</td>
                            <td style="max-width: 250px">{{ item.description }}</td>
                            <td>
                              <v-checkbox density="compact" v-model="item.list" :disabled="disabled"></v-checkbox>
                            </td>
                            <td>
                              <v-checkbox density="compact" v-model="item.create" :disabled="disabled"></v-checkbox>
                            </td>
                            <td>
                              <v-checkbox density="compact" v-model="item.update" :disabled="disabled"></v-checkbox>
                            </td>
                            <td>
                              <v-checkbox density="compact" v-model="item.delete" :disabled="disabled"></v-checkbox>
                            </td>
                            <td>
                              <v-checkbox density="compact" v-model="item.sensible" :disabled="disabled"></v-checkbox>
                            </td>
                            <td>
                              <v-checkbox density="compact" v-model="item.sidebar" :disabled="disabled"></v-checkbox>
                            </td>
                            <!-- END ROWS -->
                          </tr>
                        </template>
                      </v-data-table>
                    </v-col>
                  </v-row>
                </div>
                <!-- FIN Step 2 -->
              </div>
              <div class="steps-action">
                <v-btn v-if="step > 0" @click="prev" color="blue-grey">Atras</v-btn>
              </div>
            </v-container>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions class="pb-4">
            <v-spacer></v-spacer>
            <v-btn color="error" text @click="$emit('updateDialog', false)">Cerrar</v-btn>
            <v-btn color="success" text type="submit" :loading="loading" v-if="!disabled && step == 1">{{ formBtn }}</v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
  </v-layout>
</template>

<script>
import { rolService } from "@/libs/ws/rolService";
import ValidMixin from "@/mixins/ValidMixin.vue";

export default {
  mixins: [ValidMixin],
  components: {},
  props: {
    service: Object,
    button_name: String,
    modal_title: String,
    page_title: String,
    formBtn: String,
    dialog: Boolean,
    updateDialog: Function,
    loadList: Function,
    editItem: Object,
  },
  data() {
    return {
      show: false,
      disabled: false,
      step: 0,
      steps: [
        {
          title: "Rol",
          content: "First-content",
        },
        {
          title: "Modulos",
          content: "Second-content",
        },
      ],
      loading: false,
      loading_table: false,
      name: "",
      item: {
        id: 0,
        name: "",
        description: "",
        modules: [],
        enable: true,
        web_access: true,
        app_access: true,
      },
      defaultItem: null,
      modules: [],
      headers_modules: [
        { title: "Modulo", key: "name" },
        { title: "Descripción", key: "description" },
        { title: "Listar", key: "list", tooltip: "Permite acceder a los listados del modulo. Ej: Listados Desplegables y Tablas" },
        { title: "Crear", key: "create", tooltip: "Permite crear elementos en el modulo" },
        { title: "Editar", key: "update", tooltip: "Permite editar elementos en el modulo" },
        { title: "Eliminar", key: "delete", tooltip: "Permite borrar elementos en el modulo" },
        { title: "Sensible", key: "sensible", tooltip: "Cierta información del modulo puede declararse sensible,<br> este permiso controla la visualizacion de dicha información" },
        {
          title: "Acceso desde Sidebar",
          key: "sidebar",
          tooltip: "Ciertos modulos son accesibles desde la barra de navegacion izquierda (Sidebar),<br> este permiso controla la visualizacion del modulo en dicha barra",
        },
      ],
    };
  },
  watch: {
    dialog() {
      if (this.dialog) {
        this.disabled = this.formBtn == "";
        this.step = 0;

        if (this.$refs.form) {
          this.$refs.form.resetValidation();
        }

        if (this.editItem != null && this.editItem.id > 0) {
          this.item = JSON.parse(JSON.stringify(this.editItem));
        } else {
          if (this.defaultItem != null) this.item = JSON.parse(JSON.stringify(this.defaultItem));
        }
      } else {
        this.loadModules();
      }
    },
  },
  mounted() {
    this.loadModules();
    this.defaultItem = JSON.parse(JSON.stringify(this.item));
  },
  methods: {
    prev() {
      this.step--;
    },
    async next() {
      const { valid } = await this.$refs.form_step1.validate();

      if (valid) {
        this.step++;
      }
    },
    loadModules() {
      this.loading_table = true;
      rolService
        .listModules()
        .then((response) => {
          this.modules = response.result;
          this.loading_table = false;
        })
        .catch((e) => {
          this.loading_table = false;
        });
    },
    createItem() {
      if (this.loading) return;

      if (this.valid) {
        this.loading = true;

        if (this.item.id <= 0) {
          this.item.modules = this.modules;
        }
        var data = {
          id: this.item.id,
          name: this.item.name,
          description: this.item.description,
          enable: this.item.enable ? 1 : 0,
          web_access: this.item.web_access ? 1 : 0,
          app_access: this.item.app_access ? 1 : 0,
          modules: this.item.modules,
        };

        if (this.item.id > 0) {
          this.service
            .update(data)
            .then((response) => {
              if (response.status) {
                this.toast.success("El " + this.modal_title + " se modifico correctamente.");
                this.$emit("updateDialog", false);
                this.$emit("loadList");
              } else {
                if (Object.prototype.hasOwnProperty.call(response, "message")) {
                  this.toast.error(response.message);
                } else {
                  this.toast.error(response.msg);
                }
              }
            })
            .catch((response) => {
              this.toast.error(response.msg);
            })
            .finally(() => {
              this.loading = false;
            });
        } else {
          this.service
            .create(data)
            .then((response) => {
              if (response.status) {
                this.toast.success("El " + this.modal_title + " se creo correctamente");
                this.$emit("updateDialog", false);
                this.$emit("loadList");
              } else {
                if (Object.prototype.hasOwnProperty.call(response, "message")) {
                  this.toast.error(response.message);
                } else {
                  this.toast.error(response.msg);
                }
              }
            })
            .catch((e) => {
              this.toast.error(e);
            })
            .finally(() => {
              this.loading = false;
            });
        }
      }
    },
  },
};
</script>
