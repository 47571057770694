<template>
  <div>
    <v-dialog :modelValue="dialog" @click:outside="$emit('closeDialog')" transition="dialog-bottom-transition" max-width="600">
      <v-card>
        <v-card-title class="headline">Carga rapida de Ubicaciones</v-card-title>

        <v-card-text>
          <label class="fs-16 mt-3 mb-3">Ingrese los datos para la carga rápida</label>

          <v-combobox
            v-model="model.tipo_ubicacion"
            data-vv-as="tipo_ubicacion"
            name="tipo_ubicacion"
            item-title="name"
            item-value="id"
            :items="tipos"
            label="Tipo de Ubicacion*"
            autocomplete="off"
            :rules="[rules.requiredComboBox]"
            clearable
          ></v-combobox>

          <b><label class="fs-16 mt-1">Rack</label></b>
          <v-row>
            <v-col cols="12" md="6">
              <v-text-field dense data-vv-as="rack_from" label="Desde" name="rack_from" v-model="model.rack_from" :rules="[rules.required]" />
            </v-col>
            <v-col cols="12" md="6">
              <v-text-field dense data-vv-as="rack_to" label="Hasta" name="rack_to" v-model="model.rack_to" :rules="[rules.required]" />
            </v-col>
          </v-row>

          <b><label class="fs-16 mt-1">Columna</label></b>
          <v-row>
            <v-col cols="12" md="6">
              <v-text-field dense data-vv-as="col_from" label="Desde" name="col_from" v-model="model.col_from" :rules="[rules.required]" />
            </v-col>
            <v-col cols="12" md="6">
              <v-text-field dense data-vv-as="col_to" label="Hasta" name="col_to" v-model="model.col_to" :rules="[rules.required]" />
            </v-col>
          </v-row>

          <b><label class="fs-16 mt-1">Nivel</label></b>
          <v-row>
            <v-col cols="12" md="6">
              <v-text-field dense data-vv-as="level_from" label="Desde" name="level_from" v-model="model.level_from" :rules="[rules.required]" />
            </v-col>
            <v-col cols="12" md="6">
              <v-text-field dense data-vv-as="level_to" label="Hasta" name="level_to" v-model="model.level_to" :rules="[rules.required]" />
            </v-col>
          </v-row>

          <b><label class="fs-16 mt-3">Opciones</label></b>         
          <v-row class="">
            <v-col cols="12" md="6">
              <v-checkbox hide-details label="Virtual" v-model="model.virtual"></v-checkbox>
            </v-col>
            <v-col cols="12" md="6">
              <v-checkbox hide-details label="Permitir multiples productos" v-model="model.multiple_products"></v-checkbox>
            </v-col>
          </v-row>
          <v-row class="">
            <v-col cols="12" md="6">
              <v-checkbox hide-details label="Permitir multiples lotes" v-model="model.multiple_lotes"></v-checkbox>
            </v-col>            
          </v-row>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="error" text @click="$emit('closeDialog')">Cerrar</v-btn>
            <v-btn color="success" text @click="QuickLoadProcess" :loading="loading">Agregar</v-btn>
          </v-card-actions>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import Constant from "@/constants/sections";
import { ubicacionesUtils } from "@/libs/utils/ubicacionesUtils";
import { generalService } from "@/libs/ws/generalService";
import ValidMixin from "@/mixins/ValidMixin.vue";
export default {
  components: {},
  mixins: [ValidMixin],
  props: {
    dialog: Boolean,
  },
  data: () => ({
    loading: false,
    model: {
      rack_from: null,
      col_from: null,
      level_from: null,

      rack_to: null,
      col_to: null,
      level_to: null,
      
      virtual: false,
      multiple_lotes: false,      
    },
    defaultUbicacion: {
      id: null,
      tipo_ubicacion: null,
      rack: null,
      col: null,
      level: null,
      virtual: false,
      multiple_lotes: false,      
    },
    tipos: [],
  }),
  created: function () {
    window.addEventListener("keydown", (e) => {
      if (e.key == "Escape") {
        this.$emit("closeDialog");
      }
    });
  },
  mounted() {
    this.loadTiposUbicaciones();
  },
  watch: {},
  methods: {
    loadTiposUbicaciones() {
      generalService
        .filter({}, Constant.SECTION_TIPOS_UBICACIONES)
        .then((response) => {
          this.tipos = response.result.list;
        })
        .catch((e) => {
          console.log("service.load error");
          console.log(e);
        });
    },
    QuickLoadProcess() {
      this.loading = true;
      /*
      Formatos permitidos
      Rack: A-Z, AAA-ZZZ, A001-Z999
        *Solo letras
        *Comienza con 1 sola letra
        *Idem caracteres a inicio y fin si comienza con 1 letra
      Col: 1-9999
      Level: 1-9999
      */
      //TODO: validar valores
      let ubicaciones = [];
      const racks = ubicacionesUtils.generateValueBetween(this.model.rack_from.toString(), this.model.rack_to.toString());
      const cols = ubicacionesUtils.generateValueBetween(this.model.col_from.toString(), this.model.col_to.toString());
      const levels = ubicacionesUtils.generateValueBetween(this.model.level_from.toString(), this.model.level_to.toString());
      racks.forEach((rack) => {
        cols.forEach((col) => {
          levels.forEach((level) => {
            let ubicacion = JSON.parse(JSON.stringify(this.defaultUbicacion));
            (ubicacion.tipo_ubicacion = this.model.tipo_ubicacion), (ubicacion.rack = rack);
            ubicacion.col = col;
            ubicacion.level = level;            
            ubicacion.virtual = this.model.virtual;            
            ubicacion.multiple_lotes = this.model.multiple_lotes;

            ubicaciones.push(ubicacion);
          });
        });
      });
      this.$emit("processQuickLoad", ubicaciones);
      this.loading = false;
      this.$emit("closeDialog");
    },
  },
};
</script>
