<template>
  <v-layout>
    <v-dialog :modelValue="dialog" @click:outside="$emit('updateDialog', false)" max-width="600px" transition="dialog-bottom-transition">
      <v-card>
        <v-form @submit.prevent ref="form" v-model="valid" v-on:submit.prevent="createItem">
          <v-card-title>
            <span class="headline">{{ modal_title }}</span>
          </v-card-title>

          <v-card-text>
            <!-- START FORM DIALOG -->
            <div class="row">
              <div class="col-md-12">
                <v-combobox
                  v-model="item.tipo_ubicacion"
                  item-title="name"
                  item-value="id"
                  :items="tipos_ubicaciones"
                  label="Tipo de Ubicacion*"
                  autocomplete="off"
                  :rules="[rules.requiredComboBox]"
                  clearable
                ></v-combobox>

                <v-text-field label="Rack*" v-model="item.rack" :rules="[rules.required]"></v-text-field>
                <v-text-field label="Columna*" v-model="item.col" :rules="[rules.required]"></v-text-field>
                <v-text-field label="Nivel*" v-model="item.level" :rules="[rules.required]"></v-text-field>

                <b><label class="fs-16 mt-3">Opciones</label></b>                
                <v-row class="">
                  <v-col cols="12" md="6">
                    <v-checkbox hide-details label="Virtual" v-model="item.virtual"></v-checkbox>
                  </v-col>
                  <v-col cols="12" md="6">
                    <v-checkbox hide-details label="Permitir multiples productos" v-model="item.multiple_products"></v-checkbox>
                  </v-col>
                </v-row>
                <v-row class="">
                  <v-col cols="12" md="6">
                    <v-checkbox hide-details label="Permitir multiples lotes" v-model="item.multiple_lotes"></v-checkbox>
                  </v-col>
                </v-row>
              </div>
            </div>
          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="error" text @click="$emit('updateDialog', false)">Cerrar</v-btn>
            <v-btn color="success" text type="submit" :loading="loading" v-if="!disabled">{{ formBtn }}</v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
  </v-layout>
</template>

<script>
import ConstantEmployeesType from "@/constants/employee_types";
import { tipoUbicacionService } from "@/libs/ws/tipoUbicacionService";
import ValidMixin from "@/mixins/ValidMixin.vue";
export default {
  mixins: [ValidMixin],
  props: {
    service: Object,
    modal_title: String,
    page_title: String,
    formBtn: String,
    dialog: Boolean,
    updateDialog: Function,
    loadList: Function,
    editItem: Object,
  },
  data() {
    return {
      show: false,
      disabled: false,
      roles: [],
      loading: false,
      name: "",
      tipos_ubicaciones: [],
      item: {
        id: null,
        tipo_ubicacion: null,
        rack: null,
        col: null,
        level: null,
        virtual: false,        
        multiple_lotes: false,                
      },
      defaultItem: null,
    };
  },
  watch: {
    dialog() {
      if (this.dialog) {
        this.disabled = this.formBtn == "";

        if (this.$refs.form) {
          this.$refs.form.resetValidation();
        }

        if (this.editItem != null) {
          this.item = JSON.parse(JSON.stringify(this.editItem));
        } else {
          if (this.defaultItem != null) this.item = JSON.parse(JSON.stringify(this.defaultItem));
        }
      }
    },
  },
  created: function () {
    window.addEventListener("keydown", (e) => {
      if (e.key == "Escape") {
        this.$emit("updateDialog", false);
      }
    });
  },
  mounted() {
    this.loadCategoriaUbicaciones();
    this.defaultItem = JSON.parse(JSON.stringify(this.item));
  },
  methods: {
    loadCategoriaUbicaciones() {
      tipoUbicacionService
        .filter()
        .then((response) => {
          this.tipos_ubicaciones = response.result.list;
        })
        .catch((e) => {
          console.log("service.load error");
          console.log(e);
        });
    },
    async createItem() {
      const { valid } = await this.$refs.form.validate();
      if (valid) this.$emit("handlerUbicacion", this.item);
    },
  },
};
</script>
