import { wsService } from "./wsService";
import UserTypeConstant from "@/constants/user_type";

const section = "clients";
const user_type = localStorage.getItem("user_type");

function filter(param = { enable: true }) {
  if (user_type == UserTypeConstant.CLIENT) {
    //los cliente no pueden listar clientes.
    return new Promise((resolve, reject) => {
      reject(null);
    });
  }
  return wsService.make_post_request(section + "/list", param);
}

function create(param = {}) {
  return wsService.make_post_request(section + "/create", param);
}

function update(param = {}) {
  return wsService.make_put_request(section + "/update", param);
}

function remove(param = {}) {
  return wsService.make_delete_request(section + "/delete", param);
}

export const clientService = {
  section,
  filter,
  remove,
  create,
  update,
};
