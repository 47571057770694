import { wsService } from "./wsService";

const section = "asns";

const STATE_PENDIENTE= "PEN";
const STATE_CERRADO = "CER";

function filter(param = { enable: true }) {
  return wsService.make_post_request(section + "/list", param);
}

function create(param = {}) {
  return wsService.make_post_request(section + "/create", param);
}

function update(param = {}) {
  return wsService.make_put_request(section + "/update", param);
}

function remove(param = {}) {
  return wsService.make_delete_request(section + "/delete", param);
}

function list_status() {
  return wsService.make_get_request(section + "/list_status");
}

function get(param = { enable: true }) {
  return wsService.make_post_request(section + "/get", param);
}

function close(param = {}) {
  return wsService.make_post_request(section + "/close", param);
}

function receiveItem(param = {}) {
  return wsService.make_post_request(section + "/receive", param);
}

function deleteDetailReceived(param = {}) {
  return wsService.make_post_request(section + "/delete_detail_received", param);
}

function getMotivos() {
  return wsService.make_post_request(section + "/list_motivos_rechazo");
}

function getSummary(param = {}) {
  return wsService.make_post_request(section + "/list_received_summary", param);
}

export const asnService = {
  STATE_CERRADO,
  STATE_PENDIENTE,
  section,
  filter,
  remove,
  create,
  update,
  get,
  close,
  list_status,
  receiveItem,
  deleteDetailReceived,
  getMotivos,
  getSummary,
};
