<template>
  <div>
    <v-row>
      <v-col>
        <p>Origen</p>
      </v-col>
      <v-col md="1"> </v-col>
      <v-col>
        <p>Destino</p>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-combobox v-model="item.lpn" name="lpn" item-title="code" item-value="id" label="LPN*" autocomplete="off" :rules="[rules.requiredComboBox]" clearable :disabled="true"></v-combobox>

        <v-combobox
          v-model="zona_origin_selected"
          name="zonas"
          item-title="label"
          item-value="id"
          label="Zona*"
          autocomplete="off"
          :rules="[rules.requiredComboBox]"
          clearable
          :disabled="true"
        ></v-combobox>

        <v-combobox v-model="item.origin_ubicacion" name="origin_ubicacion" item-title="code" item-value="id" label="Ubicacion actual" autocomplete="off" clearable :disabled="true"></v-combobox>

        <v-combobox
          v-if="task.product != null"
          v-model="product_origin_selected"
          name="product_selected"
          item-title="name"
          item-value="id"
          label="Producto*"
          autocomplete="off"
          :rules="[rules.requiredComboBox]"
          clearable
          :disabled="true"
        ></v-combobox>

        <v-combobox
          v-if="task.movement_type == 'product'"
          v-model="item.presentation_type"
          name="presentation_type"
          item-title="name"
          item-value="id"
          :items="presentation_types"
          label="Indique la presentacion a transferir*"
          autocomplete="off"
          :rules="[rules.requiredComboBox]"
          :loading="loading_presentation_type"
          @update:modelValue="
            () => {
              if (this.item.presentation_type != null && this.item.presentation_type.id == PRESENTATION_TYPE_PALLET) {
                this.toast.error('Para mover un pallet completo seleccione la opcion \'Transferir un pallet completo hacia una ubicacion de destino\' en Tipo de Transferencia.');
              }
            }
          "
          :disabled="disabled"
        ></v-combobox>

        <v-text-field
          v-if="task.movement_type == 'product'"
          autocomplete="off"
          name="amount"
          label="Cantidad a transferir*"
          type="text"
          v-model="item.amount"
          :rules="[rules.required]"
          :disabled="disabled"
        />
      </v-col>
      <v-col class="d-flex justify-content-center align-items-center" md="1"> <font-awesome-icon icon="circle-arrow-right" size="2x" /> </v-col>
      <v-col class="d-flex justify-content-center align-items-center">
        <v-col>
          <v-combobox
            v-model="zona_destination_selected"
            name="zonas"
            item-title="label"
            item-value="id"
            :items="zonas"
            label="Zona"
            autocomplete="off"
            :disabled="disabled"
            clearable
            :loading="loading_zonas"
            @update:modelValue="
              () => {
                this.loadUbicaciones();
                this.loadLPNs();
              }
            "
          ></v-combobox>
          <v-combobox
            v-model="item.destination_ubicacion"
            name="destination_ubicacion"
            item-title="name"
            item-value="id"
            :items="ubicaciones"
            label="Ubicacion de destino"
            autocomplete="off"
            clearable
            :disabled="zona_destination_selected == null || disabled"
            :loading="loading_ubicaciones"
            @update:modelValue="
              () => {
                this.loadLPNs();
              }
            "
          ></v-combobox>

          <v-radio-group
            v-model="lpn_option_selected"
            row
            v-if="task.movement_type == 'product'"
            @update:modelValue="
              () => {
                this.loadNewLPNs();
                this.loadLPNs();
              }
            "
            :disabled="item.presentation_type == null || item.presentation_type.id == PRESENTATION_TYPE_PALLET || disabled"
          >
            <v-radio value="lpn_in_location" label="Listar LPNs en la ubicación"></v-radio>
            <v-radio value="new_lpn" label="Listar LPNs sin utilizar"></v-radio>
          </v-radio-group>

          <v-combobox
            v-model="item.destination_lpn"
            name="destination_lpn"
            item-title="code"
            item-value="id"
            :items="lpns"
            label="LPN*"
            autocomplete="off"
            :disabled="item.presentation_type == null || item.presentation_type.id == PRESENTATION_TYPE_PALLET || lpn_option_selected == null || disabled"
            clearable
            :loading="loading_lpns"
          ></v-combobox>
        </v-col>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import Constant from "@/constants/sections";
import { lpnService } from "@/libs/ws/lpnService";
import { generalService } from "@/libs/ws/generalService";
import { productoService } from "@/libs/ws/productoService";
import { zonaService } from "@/libs/ws/zonaService";
import ValidMixin from "@/mixins/ValidMixin.vue";

export default {
  created() {},
  mixins: [ValidMixin],
  components: {},
  props: {
    step: Number,
    task: Object,
    filters: Object,

    setStep: Function,
    addPropertyToTask: Function,
    disabled: Boolean,
  },
  data: () => ({
    PRESENTATION_TYPE_PALLET: productoService.PRESENTATION_TYPE_PALLET,
    item: {
      lpn: null,
      destination_lpn: null,
      origin_ubicacion: null,
      //Transferencia product
      destination_ubicacion: null,
      presentation_atributes: null,
      presentation_type: null,
      amount: 1,
    },

    //Filters
    zona_origin_selected: null,
    product_origin_selected: null,
    zona_destination_selected: null,
    lpn_option_selected: null,

    lpns: [],
    ubicaciones: [],
    zonas: [],

    presentation_types: [],

    loading_ubicaciones: false,
    loading_lpns: false,
    loading_presentation_type: false,
    loading_zonas: false,
  }),
  watch: {
    item: {
      handler: function (val) {
        if (val.destination_lpn != this.task.destination_lpn) {
          this.$emit("addPropertyToTask", "destination_lpn", val.destination_lpn);
          console.log("destination_lpn", val.destination_lpn);
        }
        if (val.destination_ubicacion != this.task.destination_ubicacion) {
          this.$emit("addPropertyToTask", "destination_ubicacion", val.destination_ubicacion);
        }
        if (val.presentation_type != this.task.presentation_type) {
          console.log("presentation_type", val.presentation_type);
          this.$emit("addPropertyToTask", "presentation_type", val.presentation_type);
        }
        if (val.amount != this.task.amount) {
          this.$emit("addPropertyToTask", "amount", val.amount);
        }
      },
      deep: true,
    },
  },
  mounted() {
    this.loadComboBox();
    this.item.lpn = this.task.lpn;
    this.item.origin_ubicacion = this.task.origin_ubicacion;
    this.item.destination_lpn = this.task.destination_lpn;
    this.item.amount = this.task.amount;

    this.zona_origin_selected = this.filters.origen.zona;
    this.product_origin_selected = this.filters.origen.product;
    this.item.presentation_type = this.task.presentation_type;
  },
  methods: {
    loadComboBox() {
      this.loadPresentationsTypes();
      this.loadZonas();
    },
    loadZonas() {
      this.loading_zonas = true;
      const params = {
        almacen: this.task.almacen,
      };
      zonaService
        .filter(params)
        .then((response) => {
          if (response.status === true) {
            this.zonas = response.result.list;
          } else {
            this.zonas = [];
            this.toast.error(response.msg);
          }
        })
        .finally(() => {
          this.loading_zonas = false;
        });
    },
    loadPresentationsTypes() {
      this.loading_presentation_type = true;
      const params = {
        product: this.product_selected,
        zona: this.item.zona,
      };
      generalService
        .request(Constant.SECTION_UNITS + "/list_presentation_types")
        .then((response) => {
          this.presentation_types = response.result.list;
          this.presentation_types = this.presentation_types.filter((item) => item.id != this.PRESENTATION_TYPE_PALLET);
          if (this.task.movement_type == "product" && !this.disabled) {
            this.item.presentation_type = this.presentation_types[0];
          }
        })
        .finally(() => {
          this.loading_presentation_type = false;
        });
    },
    loadUbicaciones() {
      this.loading_ubicaciones = true;
      zonaService
        .get_ubicaciones({
          zona: this.zona_destination_selected,
        })
        .then((response) => {
          this.ubicaciones = response.result;
        })
        .finally(() => {
          this.loading_ubicaciones = false;
        });
    },
    loadLPNs() {
      if (this.lpn_option_selected != "lpn_in_location") return;

      this.loading_lpns = true;
      const params = {
        state: "L", //Ubicados,
        almacen: this.task.almacen,
        zona: this.zona_destination_selected,
        ubicacion: this.item.destination_ubicacion,
        poduct: this.product_origin_selected,
        output: "lpn",
      };
      lpnService
        .filter(params)
        .then((response) => {
          this.lpns = response.result.list;
        })
        .finally(() => {
          this.loading_lpns = false;
        });
    },
    loadNewLPNs() {
      if (this.lpn_option_selected != "new_lpn") return;

      this.loading_lpns = true;
      const params = {
        state: "N", //Not used
      };
      console.log("loadNewLPNs");
      lpnService
        .filter(params)
        .then((response) => {
          this.lpns = response.result.list;
        })
        .finally(() => {
          this.loading_lpns = false;
        });
    },
  },
};
</script>
