<template>
  <div>
    <Title :pages="[{ icon: 'boxes-stacked', page: page_title.toUpperCase() }]"></Title>
    <v-container fluid>
      <v-card class="mb-4" v-if="list_access">
        <div class="row mb-2 ps-3 pe-3 mt-0 mb-2">
          <div class="col-md-12">
            <div class="col-md-12" style="background: white">
              <p class="text-h6 mt-2 mb-0"><font-awesome-icon icon="filter" class="pr-2" />Filtros</p>
              <v-divider class="mt-0"></v-divider>
              <div class="row pt-2">
                <div class="col-md-3">
                  <v-combobox
                    v-model="filters.almacen"
                    density="comfortable"
                    name="almacen"
                    item-title="name"
                    item-value="id"
                    :items="almacenes"
                    label="Almacen"
                    autocomplete="off"
                    :disabled="disabled"
                    clearable
                  ></v-combobox>
                </div>
                <div class="col-md-3">
                  <v-combobox
                    v-model="filters.zona"
                    density="comfortable"
                    item-title="name"
                    item-value="id"
                    :items="zonas"
                    label="Zona"
                    autocomplete="off"
                    clearable
                    @update:modelValue="
                      () => {
                        this.loadUbicaciones();
                        this.filters.ubicacion = null;
                      }
                    "
                  ></v-combobox>
                </div>
                <div class="col-md-3">
                  <v-combobox
                    v-model="filters.ubicacion"
                    density="comfortable"
                    :loading="loading_ubicaciones"
                    item-title="name"
                    item-value="id"
                    :items="ubicaciones"
                    label="Ubicacion"
                    autocomplete="off"
                    clearable
                  ></v-combobox>
                </div>
                <div class="col-md-3" v-if="user_type != userTypeConstant.CLIENT">
                  <v-combobox v-model="filters.client" density="comfortable" item-title="name" item-value="id" :items="clients" label="Cliente" autocomplete="off" clearable></v-combobox>
                </div>
                <div class="col-md-3">
                  <v-combobox v-model="filters.product" density="comfortable" item-title="name" item-value="id" :items="products" label="Producto" autocomplete="off" clearable></v-combobox>
                </div>
                <div class="col-md-3">
                  <v-combobox v-model="filters.state" density="comfortable" item-title="name" item-value="id" :items="states" label="Estado" autocomplete="off" clearable></v-combobox>
                </div>
                <div class="col-md-3">
                  <v-text-field v-model="filters.lote" density="comfortable" label="Lote" :disabled="disabled"></v-text-field>
                </div>
                <div class="col-md-3">
                  <v-text-field v-model="filters.serie" density="comfortable" label="Serie" :disabled="disabled"></v-text-field>
                </div>

                <div :class="user_type == userTypeConstant.CLIENT ? 'col-md-3 pt-2' : 'col-md-12 mb-2'" style="display: flex; justify-content: end">
                  <v-btn color="info" class="" @click="filter" prepend-icon="mdi-magnify">Buscar</v-btn>
                </div>
              </div>
            </div>
          </div>
        </div>
      </v-card>

      <v-card v-if="list_access">
        <v-card-title>
          <v-row align="center" class="mt-1">
            <v-col>
              <span>{{ page_title }}</span>
            </v-col>
            <v-spacer></v-spacer>
            <v-col>
              <v-text-field v-model="search" append-inner-icon="mdi-magnify" single-line hide-details label="Buscar" density="compact"></v-text-field>
            </v-col>
          </v-row>
        </v-card-title>

        <!-- v-if="list_access" -->

        <v-data-table :headers="headers" :items="list" :search="search" :loading="loading" loading-text="Cargando...">
          <template v-slot:item="{ item }">
            <tr>
              <td>{{ item.presentation_atributes.product_presentation.product.sku }}</td>
              <td>{{ item.presentation_atributes.product_presentation.product.name }}</td>
              <td>{{ item.presentation_atributes.product_presentation.base_unit.barcode }}</td>
              <td>{{ item.presentation_atributes.product_presentation.base_unit.presentation_type.name }}</td>
              <td v-if="user_type != userTypeConstant.CLIENT">{{ item.presentation_atributes.product_presentation.product.client.name }}</td>
              <td>{{ item.lpn.code }}</td>
              <td>{{ item.presentation_atributes.expiration_date }}</td>
              <td>{{ item.presentation_atributes.lote }}</td>
              <td>{{ item.presentation_atributes.serie }}</td>
              <td>{{ item.presentation_atributes.product_status.name }}</td>
              <td>{{ item.ubicacion.zona.name }}</td>
              <td>{{ item.ubicacion.name }}</td>
              <td class="text-center">{{ item.available_stock }}</td>
            </tr>
          </template>
        </v-data-table>
      </v-card>
    </v-container>
  </div>
</template>

<script>
import { inventoryService } from "@/libs/ws/inventoryService";
import { almacenService } from "@/libs/ws/almacenService";
import { clientService } from "@/libs/ws/clientService";
import { zonaService } from "@/libs/ws/zonaService";
import { productoService } from "@/libs/ws/productoService";
import UserTypeConstant from "@/constants/user_type";
import GenericMixin from "@/mixins/GenericMixin.vue";
import RolMixin from "@/mixins/RolMixin.vue";
export default {
  components: {},
  mixins: [GenericMixin, RolMixin],
  data() {
    return {
      service: inventoryService,
      page_title: "Inventario",
      userTypeConstant: UserTypeConstant,
      user_type: null,
      headers: [
        {
          title: "Sku",
          align: "left",
          sortable: true,
          key: "presentation_atributes.product_presentation.product.sku",
        },
        {
          title: "Producto",
          align: "left",
          sortable: true,
          key: "presentation_atributes.product_presentation.product.name",
        },
        {
          title: "Codigo",
          align: "left",
          sortable: true,
          key: "presentation_atributes.product_presentation.base_unit.barcode",
        },
        {
          title: "Presentacion",
          align: "left",
          sortable: true,
          key: "presentation_atributes.product_presentation.base_unit.presentation_type.name",
        },
        {
          title: "Cliente",
          align: "left",
          sortable: true,
          key: "presentation_atributes.product_presentation.product.client.name",
          visible_for_client: false,
        },
        {
          title: "LPN",
          align: "left",
          sortable: true,
          key: "lpn.code",
        },
        {
          title: "Vencimiento",
          align: "left",
          sortable: true,
          key: "presentation_atributes.expiration_date",
        },
        {
          title: "Lote",
          align: "left",
          sortable: true,
          key: "presentation_atributes.lote",
        },
        {
          title: "Serie",
          align: "left",
          sortable: true,
          key: "presentation_atributes.serie",
        },
        {
          title: "Estado",
          align: "left",
          sortable: true,
          key: "presentation_atributes.product_status.name",
        },
        {
          title: "Zona",
          align: "left",
          sortable: true,
          key: "ubicacion.zona.name",
        },
        {
          title: "Ubicación",
          align: "left",
          sortable: true,
          key: "ubicacion.name",
        },
        {
          title: "Stock",
          align: "center",
          sortable: true,
          key: "available_stock",
        },
      ],
      filters: {
        almacen: null,
        zona: null,
        ubicacion: null,
        product: null,
        state: null,
        lote: null,
        serie: null,
        client: null,
      },
      loading_ubicaciones: false,
      almacen: null,
      almacenes: [],
      clients: [],
      zonas: [],
      ubicaciones: [],
      products: [],
      states: [],
    };
  },
  mounted() {
    this.user_type = localStorage.getItem("user_type");
    //recorro los headers y saco el cliente si el usuario es el cliente
    this.headers = this.headers.filter((header) => {
      if (header.visible_for_client || header.visible_for_client == undefined) {
        return true;
      }
      return this.user_type != UserTypeConstant.CLIENT;
    });

    this.defaultItem = JSON.parse(JSON.stringify(this.mainItem));
    this.loadList(this.service);
    this.loadAlmacenes();
    this.loadClientes();
    this.loadZonas();
    this.loadStatesProducts();
    this.loadProducts();
  },
  methods: {
    loadStatesProducts() {
      productoService.getStates().then((response) => {
        this.states = response.result.list;
      });
    },
    loadProducts() {
      productoService.filter().then((response) => {
        this.products = response.result.list;
      });
    },
    loadZonas() {
      const params = {
        almacen: this.almacen,
      };
      zonaService
        .filter(params)
        .then((response) => {
          if (response.status === true) {
            this.zonas = response.result.list;
          } else {
            this.zonas = [];
            this.toast.error(response.msg);
          }
        })
        .finally(() => {});
    },
    loadUbicaciones() {
      this.loading_ubicaciones = true;
      zonaService
        .get_ubicaciones({
          zona: this.filters.zona,
        })
        .then((response) => {
          this.ubicaciones = response.result;
        })
        .finally(() => {
          this.loading_ubicaciones = false;
        });
    },
    loadClientes() {
      clientService
        .filter({})
        .then((response) => {
          this.clients = response.result.list;
        })
        .catch((e) => {
          console.log("service.load error");
          console.log(e);
        });
    },
    loadAlmacenes() {
      almacenService
        .filter({})
        .then((response) => {
          this.almacenes = response.result.list;
        })
        .catch((e) => {
          console.log("service.load error");
          console.log(e);
        });
    },
    getFilterParams() {
      return this.filters;
    },
    filter() {
      this.loadList(this.service, this.filters);
    },
  },
};
</script>
