const SECRET_PHRASE = "ewgew54ew69gWEGwe*";

const SECTION_PROVINCIAS = "provincias";

const SECTION_EMPLOYEES = "employees";
const SECTION_OPERATORS = "operators";
const SECTION_ROLES = "roles";

const SECTION_CLIENTS = "clients";
const SECTION_SUBCLIENTS = "subclients";

//PRODUCTOS
const SECTION_PRODUCTS = "products";
const SECTION_RUBROS = "categories";
const SECTION_SUBRUBROS = "subcategories";
const SECTION_SUPPLIER = "suppliers";
const SECTION_RULES = "rules";
const SECTION_UNITS = "units";
const SECTION_PRODUCT_TYPES = "product_types";

//ALMACENAMIENTO
const SECTION_SUCURSALES = "sucursales";
const SECTION_ALMACENES = "almacenes";
const SECTION_CATEGORIAS_ZONAS = "categorias_zonas";
const SECTION_TIPOS_UBICACIONES = "tipos_ubicaciones";
const SECTION_ZONAS = "zonas";
const SECTION_UBICACIONES = "ubicaciones";

//TAREAS
const SECTION_TASKS = "tasks";
const SECTION_INVENTORY = "inventory";

///////
const SECTION_MONITORING = "dashboard";

//RECEPCION
const SECTION_ASN = "asns";
const SECTION_LPN = "lpn_labels";
const SECTION_DOCKS = "docks";

//EXPEDICION
const SECTION_SHIPMENT_ORDERS = "shipment-orders";
const SECTION_PICKING_DETAIL = "picking-detail";

export default {
  SECRET_PHRASE: SECRET_PHRASE,

  //GENERAL
  SECTION_PROVINCIAS: SECTION_PROVINCIAS,
  SECTION_MONITORING: SECTION_MONITORING,
  SECTION_LPN: SECTION_LPN,

  //USERS
  SECTION_ROLES: SECTION_ROLES,
  SECTION_EMPLOYEES: SECTION_EMPLOYEES,
  SECTION_OPERATORS: SECTION_OPERATORS,
  SECTION_CLIENTS: SECTION_CLIENTS,
  SECTION_SUBCLIENTS: SECTION_SUBCLIENTS,

  //ALMACENAMIENTO
  SECTION_SUCURSALES: SECTION_SUCURSALES,
  SECTION_ALMACENES: SECTION_ALMACENES,
  SECTION_CATEGORIAS_ZONAS: SECTION_CATEGORIAS_ZONAS,
  SECTION_TIPOS_UBICACIONES: SECTION_TIPOS_UBICACIONES,
  SECTION_ZONAS: SECTION_ZONAS,
  SECTION_UBICACIONES: SECTION_UBICACIONES,
  

  //PRODUCTO
  SECTION_PRODUCTS: SECTION_PRODUCTS,
  SECTION_RUBROS: SECTION_RUBROS,
  SECTION_SUBRUBROS: SECTION_SUBRUBROS,
  SECTION_SUPPLIER: SECTION_SUPPLIER,
  SECTION_RULES: SECTION_RULES,
  SECTION_UNITS: SECTION_UNITS,
  SECTION_PRODUCT_TYPES: SECTION_PRODUCT_TYPES,

  //RECEPCION
  SECTION_ASN: SECTION_ASN,
  SECTION_DOCKS: SECTION_DOCKS,

  //TAREAS
  SECTION_TASKS: SECTION_TASKS,
  SECTION_INVENTORY: SECTION_INVENTORY,

  //EXPEDICION
  SECTION_SHIPMENT_ORDERS: SECTION_SHIPMENT_ORDERS,
  SECTION_PICKING_DETAIL: SECTION_PICKING_DETAIL,
};
