<template>
  <div class="login bg-dark">
    <v-row align="center" justify="center">
      <v-col md="4" class="pa-0">
        <v-card :border="false" theme="dark" flat class="rounded-0 h-100 bg-dark m-auto" max-width="450">
          <template v-slot:loader="{}"> </template>
          <v-card-title outlined class="d-flex justify-content-center">
            <v-img :src="require('@/assets/images/logo.png')" contain max-width="170" class="pt-10" />
          </v-card-title>
          <v-card-text class="padding mt-5">
            <v-form>
              <p v-if="login_error != ''" class="text-center text-red">Los datos ingresados son incorrectos</p>
              <v-text-field label="Username" v-model="username" prepend-icon="mdi-account" v-on:keyup.enter="auth" dark />
              <v-text-field
                :append-inner-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
                @click:append-inner="show = !show"
                :type="show ? 'text' : 'password'"
                label="Password"
                v-model="password"
                prepend-icon="mdi-lock"
                v-on:keyup.enter="auth"
              />
            </v-form>
          </v-card-text>
          <v-card-actions class="padding">
            <v-btn class="mb-8 btn text-capitalize text-white" rounded="xl" height="50" tonal block v-on:click="auth" :loading="loading" v-on:keyup.enter="auth">Login</v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
      <v-col md="8" class="bg"></v-col>
    </v-row>
  </div>
</template>

<script>
import { authService } from "@/libs/ws/authService";
import Constant from "@/constants/sections";
var CryptoJS = require("crypto-js");
export default {
  name: "App",

  components: {},

  data: () => ({
    login_error: "",
    username: "",
    password: "",
    show: false,
    loading: false,
  }),

  methods: {
    auth: function () {
      this.loading = true;
      authService
        .login(this.username, this.password)
        .then((response) => {
          if (!response.status) {
            this.login_error = response.msg;
          } else {
            localStorage.setItem("token", response.result.token);
            localStorage.setItem("expire_in", response.result.expire_in);
            localStorage.setItem("username", response.result.username);
            const encryptedText = CryptoJS.AES.encrypt(JSON.stringify(response.result.access), Constant.SECRET_PHRASE).toString();

            localStorage.setItem("access", encryptedText);
            localStorage.setItem("user_type", response.result.user_type);
            localStorage.setItem("app_object", JSON.stringify(response.result.app_object)); //Representa el id segun el tipo de usuario: Client o Employee
            window.location.reload();
          }
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },
  },
};
</script>
