<script>
// definir un objeto mixin
export default {
  data() {
    return {
      valid: false,
      rules: {
        notRequired: (value) => true,
        required: (value) => !!value || "El campo es obligatorio.",
        requiredComboBox: (value) => (value != null && Object.prototype.hasOwnProperty.call(value, "id")) || "Debe seleccionar una opcion.",
        requiredComboBoxOnlyValue: (value) => value != null || "Debe seleccionar una opcion.",
        max3Length: (value) => value.length <= 3 || "El campo debe tener maximo 3 caracteres.",
        max11Length: (value) => value.length <= 11 || "El campo debe tener maximo 11 caracteres.",
        email: (value) => /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/i.test(value) || value == "" || "El campo email no tiene un formato valido.",
        integer: (value) => /[0-9-]+/.test(value) || value == "" || "El campo debe ser numerico.",
        decimal: (value) => /[0-9-]+/.test(value) || value == null || value == "" || "El campo debe ser numerico.",
      },
    };
  },
  created: function () {},
  computed: {},
  mounted() {},
  methods: {},
};
</script>
