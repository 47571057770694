<template>
  <v-dialog :modelValue="dialog" @click:outside="$emit('updateDialog')" width="750">
    <v-card>
      <v-card-title class="title-modal">
        <span class="headline">Presentación</span>
      </v-card-title>

      <v-card-text>
        <v-form ref="form">
          <v-text-field autocomplete="off" name="name" label="Nombre*" type="text" v-model="item.name" :rules="[rules.required]" />

          <div class="row">
            <div class="col">
              <v-text-field autocomplete="off" name="barcode" label="Codigo de barras" type="text" v-model="item.barcode" />
            </div>
            <div class="col">
              <v-combobox
                v-model="item.presentation_type"
                name="presentation_type"
                item-title="name"
                item-value="id"
                :items="presentation_types"
                label="Tipo de presentacion*"
                autocomplete="off"
                :rules="[rules.requiredComboBox]"
              ></v-combobox>
            </div>
          </div>

          <div class="row mt-0" v-if="getPresentations(item).length > 0">
            <div class="col">
              <v-combobox
                v-if="getPresentations(item).length > 0"
                v-model="item.composite_presentation"
                name="composite_presentation"
                item-title="name"
                item-value="id"
                :items="getPresentations(item)"
                label="Composición de otra presentacion"
                autocomplete="off"
              ></v-combobox>
            </div>
            <div class="col">
              <v-text-field
                v-if="getPresentations(item).length > 0"
                :disabled="item.composite_presentation == null"
                autocomplete="off"
                name="amount_composite_presentation"
                label="Cantidad de la composición"
                :rules="[rules.decimal]"
                type="number"
                v-model="item.amount_composite_presentation"
              />
            </div>
          </div>
          <v-combobox
            v-model="item.storage_unit"
            name="storage_unit"
            item-title="name"
            item-value="id"
            :items="storage_units"
            label="Unidad de almacenamiento*"
            autocomplete="off"
            :rules="[rules.requiredComboBox]"
          ></v-combobox>

          <div class="row">
            <div class="col">
              <v-combobox v-model="item.weight_unit" name="weight_unit" item-title="name" item-value="id" :items="weight_units" label="Unidad de peso" autocomplete="off"></v-combobox>
            </div>
            <div class="col">
              <v-text-field autocomplete="off" name="weight" label="Peso" :rules="item.weight ? [rules.decimal] : []" type="number" v-model="item.weight" />
            </div>
          </div>

          <div class="row">
            <div class="col">
              <v-combobox v-model="item.measure_unit" name="measure_unit" item-title="name" item-value="id" :items="measure_units" label="Unidad de medida" autocomplete="off"></v-combobox>
            </div>
            <div class="col">
              <v-text-field autocomplete="off" name="heigth" label="Alto" :rules="item.heigth ? [rules.decimal] : []" type="number" v-model="item.heigth" />
            </div>
            <div class="col">
              <v-text-field autocomplete="off" name="width" label="Ancho" :rules="item.width ? [rules.decimal] : []" type="number" v-model="item.width" />
            </div>
            <div class="col">
              <v-text-field autocomplete="off" name="length" label="Largo" :rules="item.length ? [rules.decimal] : []" type="number" v-model="item.length" />
            </div>
          </div>
          <v-checkbox label="Visible en modulo de ventas" v-model="item.sellable"></v-checkbox>
        </v-form>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="red" text @click="$emit('updateDialog')"> Cancelar </v-btn>
        <v-btn color="green" text @click="addNewPresentation()"> {{ itemEdit != null ? "Editar" : "Agregar" }} </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import Constant from "@/constants/sections";
import { generalService } from "@/libs/ws/generalService";
import ValidMixin from "@/mixins/ValidMixin.vue";
export default {
  created() {},
  mixins: [ValidMixin],
  components: {},
  props: {
    dialog: Boolean,
    product: Object,
    updateDialog: Function,
    addPresentation: Function,
    itemEdit: Object,
  },
  data: () => ({
    item: {
      name: "",
      barcode: "",
      presentation_type: null,
      composite_presentation: null,
      amount_composite_presentation: null,
      storage_unit: null,
      weight_unit: null,
      weight: "",
      measure_unit: null,
      heigth: "",
      width: "",
      length: "",
      sellable: true,
    },
    defaultItem: null,
    presentations: [],
    weight_units: [],
    measure_units: [],
    storage_units: [],
    presentation_types: [],
  }),
  watch: {
    dialog() {
      if (this.itemEdit == null) {
        this.item = JSON.parse(JSON.stringify(this.defaultItem));
      } else {
        this.item = JSON.parse(JSON.stringify(this.itemEdit));
      }
    },
  },
  mounted() {
    this.loadComboData();
    this.defaultItem = JSON.parse(JSON.stringify(this.item));
  },
  methods: {
    getPresentations(item) {      
      let presentations_aux = this.product.presentations.filter(function (x) {
        return x != item;
      });

      return this.presentations.concat(presentations_aux);
    },
    loadComboData() {
      generalService.request(Constant.SECTION_UNITS + "/list_measure_units").then((response) => {
        this.measure_units = response.result.list;
      });
      generalService.request(Constant.SECTION_UNITS + "/list_weight_units").then((response) => {
        this.weight_units = response.result.list;
      });
      generalService.request(Constant.SECTION_UNITS + "/list_storage_units").then((response) => {
        this.storage_units = response.result.list;
      });
      generalService.request(Constant.SECTION_UNITS + "/list_presentation_types").then((response) => {
        this.presentation_types = response.result.list;
      });

      if (this.product.id > 0)
        generalService.request(Constant.SECTION_PRODUCTS + "/presentations", "POST", { product: this.product.id }).then((response) => {
          this.presentations = response.result.list;
        });
    },
    async addNewPresentation() {
      const { valid } = await this.$refs.form.validate();
      console.log("valid",valid,this.$refs.form)
      console.log(this.item.amount_composite_presentation)
      if (valid) {
        this.$emit("addPresentation", this.item);
      }
    },
  },
};
</script>
