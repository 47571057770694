<template>
  <div>
    <Title :pages="[{ icon: 'location-dot', page: page_title.toUpperCase() }]"></Title>

    <v-container fluid>
      <v-card class="mb-4">
        <div class="row mb-2 ps-3 pe-3 mt-0 mb-2">
          <div class="col-md-12">
            <div class="col-md-12" style="background: white">
              <p class="text-h6 mt-2 mb-0"><font-awesome-icon icon="filter" class="pr-2" />Filtros</p>
              <v-divider class="mt-0"></v-divider>
              <div class="row pt-2">
                <div class="col-md-4">
                    <v-combobox
                      v-model="filters.zona"
                      item-title="name"
                      item-value="id"
                      :items="zonas"
                      label="Zona"
                      autocomplete="off"
                      :disabled="disabled"
                      clearable
                      :loading="loading_zonas"
                    ></v-combobox>
                </div>

                <div class="col-md-8 mt-2" style="display: flex; justify-content: end">
                  <v-btn color="info" class="" @click="filter" prepend-icon="mdi-magnify">Buscar</v-btn>
                </div>
              </div>
            </div>
          </div>
        </div>
      </v-card>

      <v-card v-if="list_access">
        <v-card-title>
          <v-row align="center" class="mt-1">
            <v-col>
              <span>{{ page_title }}</span>
            </v-col>
            <v-spacer></v-spacer>
            <v-col>
              <v-text-field v-model="search" append-inner-icon="mdi-magnify" single-line hide-details label="Buscar"
                density="compact"></v-text-field>
            </v-col>
          </v-row>
        </v-card-title>
        <div class="mb-4 mt-2" v-show="update_access">
          <v-tooltip bottom>
            <template v-slot:activator="{ props }">
              <v-icon :color="selected.length == 0 ? 'grey' : 'purple'"
                @click="selected.length == 0 ? null : printSeleted()"
                :class="selected.length == 0 ? 'ml-4 cursor-default' : 'ml-4 cursor-pointer'" v-bind="props">
                mdi-printer</v-icon>
            </template>
            <span>Imprimir seleccionados</span>
          </v-tooltip>
        </div>
        <v-data-table :headers="headers" :items="list" :search="search" :loading="loading" v-model="selected"
          show-select loading-text="Cargando...">
          <template v-slot:item="{ item, isSelected, toggleSelect }">
            <tr>
              <td class="pl-2">
                <v-checkbox style="margin: 0px; padding: 0px" hide-details :model-value="isSelected({ value: item.id })"
                  @update:modelValue="toggleSelect({ value: item.id })" />
              </td>
              <td>
                {{ item.zona != null ? item.zona.name : "-" }}
              </td>
              <td>
                {{ item.tipo_ubicacion.name }}
              </td>
              <td>
                {{ item.rack }}
              </td>
              <td>
                {{ item.col }}
              </td>
              <td>
                {{ item.level }}
              </td>
              <td>
                <v-checkbox hide-details label="" :disabled="true" v-model="item.virtual"></v-checkbox>
              </td>
              <td>
                <v-checkbox hide-details label="" :disabled="true" v-model="item.multiple_lotes"></v-checkbox>
              </td>
              <td class="text-center">
                <v-tooltip bottom>
                  <template v-slot:activator="{ props }">
                    <v-icon v-bind="props" small color="purple" class="mr-2" v-on:click="print(item)" size="small">
                      mdi-printer</v-icon>
                  </template>
                  <span>Imprimir</span>
                </v-tooltip>
              </td>
            </tr>
          </template>
        </v-data-table>
      </v-card>

      <DialogDelete :dialog_delete="dialog_delete" @updateDialogDelete="updateDialogDelete"
        @deleteItemConfirm="deleteItemConfirm" :loading="loadingSubmit" />

      <ModalPrint :show="dialog_print" @close="dialog_print = false" :selected="selected" :list="list"
        @cleanSelected="selected = []" />

      <v-pagination v-if="cant_pages > 1" v-model="current_page" :length="cant_pages" @next="loadPage(service)"
        @prev="loadPage(service)" @first="loadPage(service)" @update:modelValue="loadPage(service)"></v-pagination>
    </v-container>
  </div>
</template>

<script>
import { ubicacionService } from "@/libs/ws/ubicacionService";
import GenericMixin from "@/mixins/GenericMixin.vue";
import RolMixin from "@/mixins/RolMixin.vue";
import ModalPrint from "./ModalPrint.vue";
import { zonaService } from "@/libs/ws/zonaService";
export default {
  components: { ModalPrint },
  mixins: [GenericMixin, RolMixin],
  RolMixin,
  data() {
    return {
      service: ubicacionService,
      page_title: "Ubicaciones",
      modal_title: "Ubicacion",
      dialog_print: false,
      selected: [],
      zonas: [],
      loading_zonas: false,
      filters: {
        zona: "",
      },
      headers: [
        { title: "Zona", key: "zona.name", sortable: false, class: "" },

        { title: "Tipo", key: "tipo_ubicacion", sortable: false, class: "" },
        { title: "Rack", key: "rack", sortable: false, class: "bg-grey" },
        { title: "Columna", key: "col", sortable: false, class: "bg-grey" },
        { title: "Nivel", key: "level", sortable: false, class: "bg-grey" },       
        { title: "Virtual", key: "virtual", sortable: false, class: "bg-blue" },        
        { title: "Permitir mult. lotes", align: "center", key: "multiple_lotes", sortable: false, class: "" },        
        { title: "Accion", key: "accion", sortable: false, class: "" },
      ],
    };
  },
  mounted() {
    this.loadList(this.service);
    this.loadZonas();
  },
  methods: {
    loadZonas() {
      this.loading_zonas = true;
      zonaService
        .filter()
        .then((response) => {
          this.zonas = response.result.list;
        })
        .catch((e) => {
          console.log("service.load error");
          console.log(e);
        })
        .finally(() => {
          this.loading_zonas = false;
        });
    },
    getFilterParams() {
      return this.filters;
    },
    filter() {
      this.loadList(this.service);
    },
    printSeleted() {
      this.dialog_print = true;
    },
    print(item) {
      this.selected = [];
      this.selected.push(item.id);
      this.dialog_print = true;
    },
  },
};
</script>
