<template>
  <div>
    <v-row>
      <v-col md="12" v-if="!isViewModal">
        <v-btn size="small" color="blue-grey" prepend-icon="$plus" class="pl-4 text-white" @click="newProduct">
          <span>Agregar Detalle ASN</span>
        </v-btn>
      </v-col>

      <v-col md="12">
        <v-data-table :headers="header_custome" :items="asn.asn_details" style="border: solid 1px #1c1c1c" :hide-default-footer="true" :items-per-page="100">
          <template v-slot:item="{ item }">
            <tr>
              <td>
                {{ item.product.sku }}
              </td>
              <td>
                {{ item.product.name }}
              </td>
              <td>
                {{ item.expected_amount }}
              </td>
              <!--
              <td>
                {{ item.lpn }}
              </td>
              <td>
                {{ item.expected_lote }}
              </td>
              <td>
                {{ item.expected_serie }}
              </td>
              <td>
                {{ item.expected_manufacture_date }}
              </td>
              <td>
                {{ item.expected_expiration_date }}
              </td>

              <td>
                {{ item.external_line_number }}
              </td>
              -->

              <td class="text-center" style="width: 110px" v-if="!isViewModal">
                <v-tooltip bottom>
                  <template v-slot:activator="{ props }">
                    <v-icon v-bind="props" small color="blue" class="mr-2" v-on:click="editProduct(item)" size="small"> mdi-pencil</v-icon>
                  </template>
                  <span>Editar</span>
                </v-tooltip>

                <v-tooltip bottom>
                  <template v-slot:activator="{ props }">
                    <v-icon v-bind="props" small color="red" class="mr-2" v-on:click="_removeProduct(item, 'custom')" size="small">mdi-delete </v-icon>
                  </template>
                  <span>Remover</span>
                </v-tooltip>
              </td>
            </tr>
          </template>
        </v-data-table>
      </v-col>
    </v-row>

    <!-- START FORM DIALOG -->
    <ModalPresentacion :dialog="dialog_presentacion" :itemEdit="itemEdit" :asn="asn" @updateDialog="dialog_presentacion = false" @addProduct="_addProduct" />
  </div>
</template>

<script>
import ModalPresentacion from "./ModalPresentacion.vue";
export default {
  created() {
    this.$validator = this.validator;
  },
  components: { ModalPresentacion },
  props: {
    open: Boolean,
    asn: Object,
    addProduct: Function,
    removeProduct: Function,
    validator: Object,
    isViewModal: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    header_custome: [
      { title: "SKU", key: "product.sku", sortable: false }, 
      { title: "Producto", key: "product.name", sortable: false },      
      { title: "Cantidad", key: "expected_amount", sortable: false },
      /*
      { title: "LPN", key: "lpn", sortable: true },
      { title: "Lote", key: "expected_lote", sortable: false },
      { title: "Serie", key: "expected_serie", sortable: false },
      { title: "Fecha de Manufactura", key: "expected_manufacture_date", sortable: false },
      { title: "Fecha de Expiracion", key: "expected_expiration_date", sortable: false },
      { title: "Numero de linea externo", key: "external_line_number", sortable: false },
       */
      { title: "Accion", sortable: false },
    ],
    ivas: [],
    dialog_presentacion: false,
    itemEdit: null,
    itemEditIndex: null,
  }),
  watch: {
    asn: {
      handler: function (newValue) {},
      deep: true,
    },
  },
  mounted() {},
  methods: {
    newProduct() {
      this.itemEdit = null;
      this.itemEditIndex = null;
      this.dialog_presentacion = true;
    },
    editProduct(item) {
      this.itemEditIndex = this.asn.asn_details.indexOf(item);
      this.itemEdit = Object.assign({}, item);
      this.dialog_presentacion = true;
    },
    _addProduct(item) {
      if (this.itemEditIndex == null) {
        this.$emit("addProduct", item);
      } else {
        this.$emit("addProduct", item, this.itemEditIndex);
      }
      this.dialog_presentacion = false;
    },
    _removeProduct(item, colection_name) {
      if (colection_name == "custom") {
        const index_item = this.asn.asn_details.indexOf(item);
        if (index_item > -1) this.$emit("removeProduct", index_item);
      }
    },
  },
};
</script>
