<template>
  <v-layout>
    <v-dialog :modelValue="dialog" @click:outside="$emit('updateDialog', false)" max-width="700px" transition="dialog-bottom-transition">
      <v-card>
        <v-form>
          <v-card-title>
            <span class="headline">Resumen de recepción ASN #{{ asn != null ? asn.id : "" }}</span>
          </v-card-title>

          <v-card-text>
            <v-data-table :headers="headers" :items="list" :loading="loading" loading-text="Cargando...">
              <template v-slot:item="{ item }">
                <tr>
                  <td>{{ item.product.sku }}</td>
                  <td>{{ item.product.name }}</td>
                  <td>{{ item.expected_amount }}</td>
                  <td>{{ item.base_amount_received }}</td>
                  </tr>
              </template>
            </v-data-table>
          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="error" text @click="$emit('updateDialog', false)">Cerrar</v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
  </v-layout>
</template>

<script>
import { asnService } from "@/libs/ws/asnService";

export default {
  components: {},
  mixins: [],
  props: {
    dialog: Boolean,
    asn: { Type: Object, default: null },
  },
  data() {
    return {
      list: [],
      loading: false,
      headers: [
        { title: "SKU", key: "product.sku" },
        { title: "Producto", key: "product.name" },
        { title: "Cantidad esperada", key: "expected_amount" },
        { title: "Cantidad recibida", key: "base_amount_received	" },
      ],
    };
  },
  watch: {
    dialog() {
      if (this.dialog) {
        this.getSummary();
      }
    },
  },
  mounted() {},
  methods: {
    getSummary() {
      if(this.loading) return;
      this.loading = true;
      asnService.getSummary({ id: this.asn.id }).then((response) => {
        if(response.status === true) {
          this.list = response.result;
        }else{
          this.$toast.error(response.msg);
        }
      })
      .finally(() => {
        this.loading = false;
      });
    },
  },
};
</script>
