<template>
  <v-layout>
    <v-dialog
      :modelValue="dialog"
      @click:outside="
        $emit('updateDialog', false);
        cleanData();
      "
      max-width="500px"
      transition="dialog-bottom-transition"
    >
      <v-card>
        <v-form @submit.prevent ref="form" v-model="valid" v-on:submit.prevent="createItem">
          <v-card-title>
            <span class="headline">Realizar Tarea de Guardado #{{ item.id }}</span>
          </v-card-title>

          <v-card-text>
            <!-- START FORM DIALOG -->
            <div class="row">
              <div class="col-md-12">
                <v-text-field
                  label="LPN Origen"
                  v-if="task != null && task.task_type != null && task.task_type.id == taskService.TASK_TRANSFERENCIA"
                  type="text"
                  v-model="origin_lpn_selected.code"
                  :rules="[rules.required]"
                  disabled
                />
                <v-text-field
                  label="Ubicació Origen"
                  v-if="task != null && task.task_type != null && task.task_type.id == taskService.TASK_TRANSFERENCIA"
                  type="text"
                  v-model="origin_ubicacion_selected_name"
                  :rules="[rules.required]"
                  disabled
                />
                <div v-if="origin_ubicacion_selected != null && ubicacion_actual != null && origin_ubicacion_selected.id != ubicacion_actual.id">
                  <v-alert class="mb-3" border="top" type="warning" variant="outlined" prominent>
                    La ubicacion actual del LPN de origen es diferente de la ubicacion de origen de la tarea. Ubicacion Actual: {{ ubicacion_actual.name }}
                  </v-alert>
                </div>

                <v-text-field v-if="item.destination_lpn != null" label="LPN Destino" type="text" v-model="item.destination_lpn.code" :rules="[rules.required]" disabled />

                <v-combobox
                  v-model="zona_destination_selected"
                  name="zonas"
                  item-title="label"
                  item-value="id"
                  :items="zonas"
                  label="Zona*"
                  autocomplete="off"
                  :disabled="disabled"
                  :rules="[rules.requiredComboBox]"
                  clearable
                  :loading="loading_zonas"
                  @update:modelValue="
                    () => {
                      this.item.destination_ubicacion = null;
                      this.ubicaciones = [];
                      this.loadUbicaciones();
                    }
                  "
                ></v-combobox>

                <v-combobox
                  v-model="item.destination_ubicacion"
                  name="destination_ubicacion"
                  item-title="name"
                  item-value="id"
                  :items="ubicaciones"
                  label="Ubicacion de destino"
                  autocomplete="off"
                  clearable
                  :disabled="zona_destination_selected == null || disabled"
                  :loading="loading_ubicaciones"
                  :rules="[rules.requiredComboBox]"
                  @update:modelValue="
                    () => {
                      this.loadNewLPNs();
                      this.loadLPNs();
                    }
                  "
                ></v-combobox>

                <div v-if="item.destination_lpn == null">
                  <!-- Hay que seleccionar el LPN de destino -->
                  <v-radio-group
                    v-model="lpn_option_selected"
                    row
                    v-if="task.movement_type == 'product'"
                    @update:modelValue="
                      () => {
                        this.loadNewLPNs();
                        this.loadLPNs();
                      }
                    "
                    :disabled="item.presentation_type == null || item.presentation_type.id == PRESENTATION_TYPE_PALLET"
                  >
                    <v-radio value="lpn_in_location" label="Listar LPNs en la ubicación"></v-radio>
                    <v-radio value="new_lpn" label="Listar LPNs sin utilizar"></v-radio>
                  </v-radio-group>
                  <v-combobox
                    v-model="destination_lpn_selected"
                    name="destination_lpn"
                    item-title="code"
                    item-value="id"
                    :items="lpns"
                    label="LPN Destino*"
                    autocomplete="off"
                    :disabled="disabled || lpn_option_selected == null || zona_destination_selected == null || item.destination_ubicacion == null"
                    :rules="[rules.requiredComboBox]"
                    clearable
                    :loading="loading_lpns"
                  ></v-combobox>
                </div>
              </div>
            </div>
          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="error"
              text
              @click="
                $emit('updateDialog', false);
                cleanData();
              "
              >Cerrar</v-btn
            >
            <v-btn color="success" text type="submit" :loading="loading">Realizar</v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
  </v-layout>
</template>

<script>
import Constant from "@/constants/sections";
import { lpnService } from "@/libs/ws/lpnService";
import { zonaService } from "@/libs/ws/zonaService";
import { taskService } from "@/libs/ws/taskService";
import ValidMixin from "@/mixins/ValidMixin.vue";
export default {
  mixins: [ValidMixin],
  props: {
    dialog: Boolean,
    updateDialog: Function,
    loadList: Function,
    task: Object,
  },
  data() {
    return {
      taskService: taskService,
      show: false,

      zonas: [],
      ubicaciones: [],

      loading: false,
      loading_zonas: false,
      loading_ubicaciones: false,
      disabled: false,

      zona_destination_selected: null,
      origin_lpn_selected: { code: null },
      origin_ubicacion_selected: null,
      origin_ubicacion_selected_name: "",
      ubicacion_actual: null,
      lpn_option_selected: "lpn_in_location",
      destination_lpn_selected: null,

      item: {
        id: null,
        lpn: { code: null },
        destination_ubicacion: null,
      },
      defaultItem: null,
    };
  },
  watch: {
    dialog() {
      if (this.dialog) {
        if (this.$refs.form) {
          this.$refs.form.resetValidation();
        }

        if (this.task != null && this.task.id > 0) {
          this.item = JSON.parse(JSON.stringify(this.task));
          this.loadZonas();
          this.origin_lpn_selected = this.task.lpn;
          this.origin_ubicacion_selected = this.task.origin_ubicacion;
          this.origin_ubicacion_selected_name = this.task.origin_ubicacion.name;

          //Obtener la ubicacion Actual
          this.getUbicacion();

          if (this.item.destination_ubicacion != null) {
            this.zona_destination_selected = this.item.destination_ubicacion.zona;
            this.loadUbicaciones();
            this.loadLPNs();
          }

          if(this.item.destination_lpn != null){
            this.destination_lpn_selected = this.item.destination_lpn;
          }
        } else {
          if (this.defaultItem != null) {
            this.item = JSON.parse(JSON.stringify(this.defaultItem));
          }
        }
      }
    },
  },
  mounted() {
    this.defaultItem = JSON.parse(JSON.stringify(this.item));
  },
  methods: {
    getUbicacion() {
      this.loading = true;
      const params = {
        lpn: this.task.lpn,
      };
      lpnService
        .scan(params)
        .then((response) => {
          if(response.status === false){
            this.toast.error(response.msg);
            return;
          }else if(response.result == null){
            this.toast.error("No se encontró la ubicación actual del LPN");
            return;
          }else{
            this.ubicacion_actual = response.result.ubicacion;
          }                  
        })
        .finally(() => {
          this.loading = false;
        });
    },
    loadZonas() {
      this.loading_zonas = true;
      const params = {
        almacen: this.task.almacen,
      };
      zonaService
        .filter(params)
        .then((response) => {
          if (response.status === true) {
            this.zonas = response.result.list;
          } else {
            this.zonas = [];
            this.toast.error(response.msg);
          }
        })
        .finally(() => {
          this.loading_zonas = false;
        });
    },
    loadUbicaciones() {
      this.loading_ubicaciones = true;
      zonaService
        .get_ubicaciones({
          zona: this.zona_destination_selected,
        })
        .then((response) => {
          this.ubicaciones = response.result;
        })
        .finally(() => {
          this.loading_ubicaciones = false;
        });
    },
    loadLPNs() {
      if (this.lpn_option_selected != "lpn_in_location") return;

      this.loading_lpns = true;
      const params = {
        state: "L", //Ubicados,
        almacen: this.task.almacen,
        zona: this.zona_destination_selected,
        ubicacion: this.item.destination_ubicacion,
        poduct: this.product_origin_selected,
        output: "lpn",
      };
      lpnService
        .filter(params)
        .then((response) => {
          this.lpns = response.result.list;
        })
        .finally(() => {
          this.loading_lpns = false;
        });
    },
    loadNewLPNs() {
      if (this.lpn_option_selected != "new_lpn") return;

      this.loading_lpns = true;
      const params = {
        state: "N", //Not used
      };
      lpnService
        .filter(params)
        .then((response) => {
          this.lpns = response.result.list;
        })
        .finally(() => {
          this.loading_lpns = false;
        });
    },
    cleanData() {
      this.item = JSON.parse(JSON.stringify(this.defaultItem));
      (this.zona_destination_selected = null), (this.origin_lpn_selected = { code: null });
      this.origin_ubicacion_selected = null;
      this.origin_ubicacion_selected_name = "";
      this.ubicacion_actual = null;
      this.lpn_option_selected = "lpn_in_location";
      this.destination_lpn_selected = null;
    },
    createItem() {
      if (this.loading) return;

      if (this.valid) {
        this.loading = true;

        var data = {
          id: this.item.id,
          destination_ubicacion: this.item.destination_ubicacion,
          destination_lpn: this.destination_lpn_selected,
        };

        this.taskService
          .perform_task(data)
          .then((response) => {
            if (response.status === true) {
              this.toast.success("La tarea #" + this.item.id + " se realizó correctamente.");
              this.cleanData();
              this.$emit("updateDialog", false);
              this.$emit("loadList");
            } else {
              if (Object.prototype.hasOwnProperty.call(response, "message")) {
                this.toast.error(response.message);
              } else {
                this.toast.error(response.msg);
              }
            }
          })
          .catch((response) => {
            this.toast.error(response.msg);
          })
          .finally(() => {
            this.loading = false;
          });
      }
    },
  },
};
</script>
