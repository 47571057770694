<template>
  <div>
    <Title :pages="[{ icon: 'user-tie', page: page_title.toUpperCase() }]"></Title>

    <v-container fluid>
      <Modal
        v-if="create_access"
        :button_name="'Crear ' + modal_title"
        :modal_title="modal_title"
        :page_title="page_title"
        :formBtn="formBtn"
        :dialog="dialog"
        :service="service"
        @updateDialog="updateDialog"
        @loadList="loadList(service)"
        :editItem="mainItem"
      />

      <v-card v-if="list_access">
        <v-card-title>
          <v-row align="center" class="mt-1">
            <v-col>
              <span>{{ page_title }}</span>
            </v-col>
            <v-spacer></v-spacer>
            <v-col>
              <v-text-field v-model="search" append-inner-icon="mdi-magnify" single-line hide-details label="Buscar" density="compact"></v-text-field>
            </v-col>
          </v-row>
        </v-card-title>

        <v-data-table :headers="headers" :items="list" :search="search" :loading="loading" loading-text="Cargando...">
          <template v-slot:item="{ item }">
            <tr>
              <td>{{ item.user.name }}</td>
              <td>{{ item.user.username }}</td>
              <td>{{ item.user.role.name }}</td>
              <td>{{ item.almacen.name }}</td>
              <td class="text-center">{{ item.user.enable ? "Si" : "No" }}</td>
              <td class="text-center">
                <v-tooltip bottom>
                  <template v-slot:activator="{ props }">
                    <v-icon v-if="update_access" v-bind="props" small color="blue" class="mr-2" v-on:click="editItem(item)" size="small"> mdi-pencil</v-icon>
                  </template>
                  <span>Editar</span>
                </v-tooltip>

                <v-tooltip bottom>
                  <template v-slot:activator="{ props }">
                    <v-icon v-bind="props" small color="blue" class="mr-2" v-on:click="viewItem(item)" size="small"> mdi-eye </v-icon>
                  </template>
                  <span>Ver</span>
                </v-tooltip>

                <v-tooltip bottom>
                  <template v-slot:activator="{ props }">
                    <v-icon v-if="delete_access" v-bind="props" small color="red" class="mr-2" v-on:click="deleteItem(item)" size="small">mdi-delete </v-icon>
                  </template>
                  <span>Eliminar</span>
                </v-tooltip>
              </td>
            </tr>
          </template>
        </v-data-table>
      </v-card>

      <DialogDelete :dialog_delete="dialog_delete" @updateDialogDelete="updateDialogDelete" @deleteItemConfirm="deleteItemConfirm" :loading="loadingSubmit" />
    </v-container>
  </div>
</template>

<script>
import { employeeService } from "@/libs/ws/employeeService.js";
import ConstantEmployeesType from "@/constants/employee_types";
import GenericMixin from "@/mixins/GenericMixin.vue";
import RolMixin from "@/mixins/RolMixin.vue";
import Modal from "./Modal.vue";

export default {
  components: { Modal },
  mixins: [GenericMixin, RolMixin],
  RolMixin,
  data() {
    return {
      service: employeeService,
      page_title: "Operarios",
      modal_title: "Operario",
      headers: [
        {
          title: "Nombre",
          align: "left",
          sortable: true,
          key: "user.name",
        },
        {
          title: "Usuario",
          align: "left",
          sortable: true,
          key: "user.username",
        },
        {
          title: "Rol",
          align: "left",
          sortable: true,
          key: "role.name",
        },
        {
          title: "Almacen",
          align: "left",
          sortable: true,
          key: "almacen.name",
        },
        {
          title: "Habilitado",
          align: "center",
          sortable: true,
          key: "enable",
        },
        { title: "Accion", align: "center", sortable: false, key: "" },
      ],
    };
  },
  mounted() {
    this.loadList(this.service);
  },
  methods: {
    getFilterParams() {
      var param = {
        employee_type: ConstantEmployeesType.TYPE_OPERATOR
      };
      return param;
    },
  },
};
</script>
