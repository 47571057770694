import { wsService } from "./wsService";
const api_url = process.env.VUE_APP_API_URL;
const section = "ubicaciones";
import axios from "axios";

function filter(param = { enable: true }) {
  return wsService.make_post_request(section + "/list", param);
}

function create(param = {}) {
  return wsService.make_post_request(section + "/create", param);
}

function update(param = {}) {
  return wsService.make_put_request(section + "/update", param);
}

function remove(param = {}) {
  return wsService.make_delete_request(section + "/delete", param);
}


function print(param = {}, type) {
  var url = "";
  if (type == "zpl") {
    url = section + "/print/label/bulk/termica_zpl";
  }
  if (type == "pdf") {
    url = section + "/print/label/bulk/termica_pdf";
  }
  const token = localStorage.getItem("token");
  return axios.post(api_url + url, param, {
    responseType: "arraybuffer",
    headers: {
      Accept: "application/pdf",
      "Content-Type": "application/json; charset=utf-8",
      Authorization: "Bearer " + token,
    },
  }).catch((error)=>{    console.error("Network Error:", error);
  });
}

export const ubicacionService = {
  section,
  filter,
  remove,
  create,
  update,
  print,
};
