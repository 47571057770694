<template>
  <div>
    <Title :pages="[{ icon: 'boxes-packing', page: page_title.toUpperCase() }]"></Title>
    <v-container fluid>
      <v-card class="mb-4">
        <div class="row mb-2 ps-3 pe-3 mt-0 mb-2">
          <div class="col-md-12">
            <div class="col-md-12" style="background: white">
              <p class="text-h6 mt-2 mb-0"><font-awesome-icon icon="filter" class="pr-2" />Filtros</p>
              <v-divider class="mt-0"></v-divider>
              <div class="row pt-2">
                <div class="col-md-3">
                  <v-text-field v-model="filters.estimated_arrival_date_from" density="comfortable" type="date" name="estimated_arrival_date_from" label="Desde" :disabled="disabled"></v-text-field>
                </div>
                <div class="col-md-3">
                  <v-text-field v-model="filters.estimated_arrival_date_to" density="comfortable" type="date" name="estimated_arrival_date_to" label="hasta" :disabled="disabled"></v-text-field>
                </div>

                <div class="col-md-3">
                  <v-text-field v-model="filters.id" density="comfortable" label="Numero" :disabled="disabled"></v-text-field>
                </div>
                <div class="col-md-3">
                  <v-combobox
                    v-model="filters.status"
                    density="comfortable"
                    name="status"
                    item-title="name"
                    item-value="id"
                    :items="status"
                    label="Estado"
                    autocomplete="off"
                    :disabled="disabled"
                    clearable
                  ></v-combobox>
                </div>
                <div class="col-md-3">
                  <v-combobox
                    v-model="filters.client"
                    density="comfortable"
                    name="cliente"
                    item-title="name"
                    item-value="id"
                    :items="clients"
                    label="Cliente"
                    autocomplete="off"
                    :disabled="disabled"
                    clearable
                  ></v-combobox>
                </div>
                <div class="col-md-3">
                  <v-combobox
                    v-model="filters.almacen"
                    density="comfortable"
                    name="almacen"
                    item-title="name"
                    item-value="id"
                    :items="almacenes"
                    label="Almacen"
                    autocomplete="off"
                    :disabled="disabled"
                    clearable
                  ></v-combobox>
                </div>

                <div class="col-md-12 pt-0 pb-3" style="display: flex; justify-content: end">
                  <v-btn color="info" class="" @click="filter" prepend-icon="mdi-magnify">Buscar</v-btn>
                </div>
              </div>
            </div>
          </div>
        </div>
      </v-card>

      <v-card>
        <v-card-title>
          <v-row align="center" class="mt-1">
            <v-col>
              <span>{{ page_title }}</span>
            </v-col>
            <v-spacer></v-spacer>
            <v-col>
              <v-text-field v-model="search" append-inner-icon="mdi-magnify" single-line hide-details label="Buscar" density="compact"></v-text-field>
            </v-col>
          </v-row>
        </v-card-title>

        <!-- v-if="list_access" -->

        <v-data-table v-if="list_access" :headers="headers" :items="list" :search="search" :loading="loading" loading-text="Cargando...">
          <template v-slot:item="{ item }">
            <tr>
              <td>{{ item.id }}</td>
              <td>{{ item.date }}</td>
              <td>{{ item.estimated_departure_date }}</td>
              <td>{{ item.wave != null ? item.wave.id : "" }}</td>
              <td>{{ item.document_type.name }}</td>
              <td>{{ item.client.user.username }}</td>
              <td>{{ item.address + ", " + item.location }}</td>
              <td>{{ item.external_reference }}</td>
              <td>{{ item.status.name }}</td>

              <td class="text-center" style="min-width: 150px">
                <v-tooltip bottom>
                  <template v-slot:activator="{ props }">
                    <v-icon v-bind="props" small color="violet" class="mr-2" v-on:click="createPickingDetail(item)" size="small"> mdi-package </v-icon>
                  </template>
                  <span>Preparar envio</span>
                </v-tooltip>

                <v-tooltip bottom>
                  <template v-slot:activator="{ props }">
                    <v-icon v-if="update_access" v-bind="props" small color="blue" class="mr-2" v-on:click="editItem(item)" size="small"> mdi-pencil</v-icon>
                  </template>
                  <span>Editar</span>
                </v-tooltip>

                <v-tooltip bottom>
                  <template v-slot:activator="{ props }">
                    <v-icon v-bind="props" small color="blue" class="mr-2" v-on:click="viewItem(item)" size="small"> mdi-eye </v-icon>
                  </template>
                  <span>Ver</span>
                </v-tooltip>

                <v-tooltip bottom>
                  <template v-slot:activator="{ props }">
                    <v-icon v-if="delete_access" v-bind="props" small color="red" class="mr-2" v-on:click="deleteItem(item)" size="small">mdi-delete </v-icon>
                  </template>
                  <span>Eliminar</span>
                </v-tooltip>
              </td>
            </tr>
          </template>
        </v-data-table>
      </v-card>
    </v-container>
  </div>
</template>

<script>
import { pickingDetailService } from "@/libs/ws/pickingDetailService";
import { almacenService } from "@/libs/ws/almacenService";
import { clientService } from "@/libs/ws/clientService";
import { documentsTypeService } from "@/libs/ws/documentsTypeService";
import GenericMixin from "@/mixins/GenericMixin.vue";
import RolMixin from "@/mixins/RolMixin.vue";
export default {
  components: {},
  mixins: [GenericMixin, RolMixin],
  data() {
    return {
      service: pickingDetailService,
      page_title: "Preparacion de envios",
      modal_title: "Preparacion de envios",
      documents_types: [],
      clients: [],
      almacenes: [],
      status: [],
      headers: [
        {
          title: "Nº",
          align: "left",
          sortable: true,
          key: "id",
        },
        {
          title: "Fecha de creacion",
          align: "left",
          sortable: true,
          key: "creation_date",
        },
        {
          title: "Usuario de creacion",
          align: "left",
          sortable: true,
          key: "user_create.username",
        },
        {
          title: "Orden de envio",
          align: "left",
          sortable: true,
          key: "amount_to_pick",
        },
        {
          title: "Producto",
          align: "left",
          sortable: true,
          key: "amount_to_pick",
        },
        {
          title: "Presentacion",
          align: "left",
          sortable: true,
          key: "amount_to_pick",
        },
        {
          title: "Cliente",
          align: "left",
          sortable: true,
          key: "presentation_atributes.product_presentation.product.client.name",
        },
        {
          title: "LPN",
          align: "left",
          sortable: true,
          key: "lpn.code",
        },
        {
          title: "Vencimiento",
          align: "left",
          sortable: true,
          key: "presentation_atributes.expiration_date",
        },
        {
          title: "Lote",
          align: "left",
          sortable: true,
          key: "presentation_atributes.lote",
        },
        {
          title: "Serie",
          align: "left",
          sortable: true,
          key: "presentation_atributes.serie",
        },
        {
          title: "Cantidad a preparar",
          align: "left",
          sortable: true,
          key: "amount_to_pick",
        },
        {
          title: "Zona",
          align: "left",
          sortable: true,
          key: "ubicacion.zona.name",
        },
        {
          title: "Ubicacion",
          align: "left",
          sortable: true,
          key: "document_type.name",
        },
        { title: "Accion", align: "center", sortable: false, key: "" },
      ],
      filters: {
        estimated_arrival_date_from: new Date().toISOString().substr(0, 10),
        estimated_arrival_date_to: new Date().toISOString().substr(0, 10),
        estimated_arrival_date: "",
        document_type: null,
        status: null,
        client: null,
        almacen: null,
        id: "",
        external_reference: "",
      },
    };
  },
  mounted() {
    this.loadList(this.service);
    this.loadAlmacenes();
    this.loadClientes();
    this.loadDocumentTypes();
    this.loadStatus();
  },
  methods: {
    getFilterParams() {
      return this.filters;
    },
    filter() {
      this.loadList(this.service);
    },
    loadAlmacenes() {
      almacenService
        .filter({})
        .then((response) => {
          this.almacenes = response.result.list;
        })
        .catch((e) => {
          console.log("service.load error");
          console.log(e);
        });
    },
    loadClientes() {
      clientService
        .filter({})
        .then((response) => {
          this.clients = response.result.list;
        })
        .catch((e) => {
          console.log("service.load error");
          console.log(e);
        });
    },
    loadDocumentTypes() {
      documentsTypeService
        .filter({ order_type: "OUT" })
        .then((response) => {
          this.documents_types = response.result.list;
        })
        .catch((e) => {
          console.log("service.load error");
          console.log(e);
        });
    },
    loadStatus() {
      pickingDetailService
        .list_status()
        .then((response) => {
          this.status = response.result.list;
        })
        .catch((e) => {
          console.log("service.load error");
          console.log(e);
        });
    },
  },
};
</script>
